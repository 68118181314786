import './../../App.css';
import { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';

import { Auth } from 'aws-amplify';

import {
    Alert, Box, Button, ButtonGroup, Container, Dialog, DialogTitle, DialogContentText, Grow,
    IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemText,
    Paper, Popper, Stack, TextField, Typography, ClickAwayListener, ListItemIcon, Tooltip,
    ToggleButtonGroup, ToggleButton, Card, Chip, Menu
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import LabelIcon from '@mui/icons-material/Label';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
// import ShortTextOutlinedIcon from '@mui/icons-material/ShortTextOutlined';
// import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { ampli, makePostRequest } from 'revelata-common-ui';
import { MIN_TS_LEN, MAX_OTHER_SECTION_LEN } from './constants';
import { ChartList } from './ChartList';
import { TableList } from './TableList';
import {
    SearchBarContext, DisplayedCikContext, SelectedTimeSeriesContext,
    DateRangeContext, ChartExpandStateContext, SelectedDatapointContext, OnboardingWizardContext, SelectedTableSeriesContext, TableSeriesExpandStateContext,
    CompanyNameContext, ExchangeNameContext
} from './Contexts';
import { exportTableXlsxWorkbook, exportXlsxWorkbook } from './XlsxUtils';
import { decodeTimeSeriesApiResponse } from './TimeSeriesApi';
import { decodeTableSeriesApiResponse } from './TableSeriesApi';

import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { toDate as datefnstz_toDate } from 'date-fns-tz';
import { OnboardingWizard, advanceWizardState } from './Onboarding.js';
import PropTypes from 'prop-types';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function truncString(s, maxLength) {
    if (s.length > maxLength) {
        let trunc = s.substr(0, maxLength);
        trunc = trunc.substr(0, Math.min(trunc.length, trunc.lastIndexOf(" ")));
        trunc += '...';
        return trunc
    }

    return s;
}

function getDataArrayWithSectionIdx(data, headerInfo) {
    // Check if data is an array
    if (!Array.isArray(data)) {
        return [];
    }

    return data.flatMap((obj) => {
        // Check if data is an array
        if (Array.isArray(obj.ts)) {
            return obj.ts.map((item) => (
                {
                    ...item,
                    sectionIdx: obj.sectionIdx,
                    section: headerInfo[obj.sectionIdx]
                }));
        } else {
            // If not an array, assume it's a single object
            return [{ ...obj, sectionIdx: obj.sectionIdx, section: headerInfo[obj.sectionIdx] }];
        }
    });
}

const sortBy = (data, key) => {
    return data.sort((a, b) => {
        const textA = a[key].toLowerCase();
        const textB = b[key].toLowerCase();
        return textA.localeCompare(textB);
    });
};

const sortBySeriesLength = (data) => {
    return data.sort((a, b) => {
        // Filter timeseries entries where canDisplay AND isInDateRange are true
        const filteredSeriesA = a.series.filter((item) => item.data.canDisplay && item.data.isInDateRange);
        const filteredSeriesB = b.series.filter((item) => item.data.canDisplay && item.data.isInDateRange);
        // Compare the length of filtered series arrays
        return filteredSeriesB.length - filteredSeriesA.length;
    });
};

const sortBySeriesLengthTable = (data) => {
    return data.sort((a, b) => {
        const filteredSeriesA = a.timeContextHeaders[0].date;
        const filteredSeriesB = b.timeContextHeaders[0].date;
        // Compare the length of filtered series arrays
        return filteredSeriesB.length - filteredSeriesA.length;
    });
};


const sortByLatestDate = (data) => {
    return data.sort((a, b) => {
        // Filter timeseries entries where canDisplay AND isInDateRange are true
        const filteredSeriesA = a.series.filter((item) => item.data.canDisplay && item.data.isInDateRange);
        const filteredSeriesB = b.series.filter((item) => item.data.canDisplay && item.data.isInDateRange);
        // Check if either series is empty (no valid entries)
        if (!filteredSeriesA.length && !filteredSeriesB.length) {
            return 0; // No dates to compare, return equal
        } else if (!filteredSeriesA.length) {
            return 1; // Series A has no valid entries, sort it lower
        } else if (!filteredSeriesB.length) {
            return -1; // Series B has no valid entries, sort it lower
        }

        // Extract and compare the latest displayDatetime
        const latestDateA = new Date(
            filteredSeriesA[filteredSeriesA.length - 1].data.displayDatetime
        );
        const latestDateB = new Date(
            filteredSeriesB[filteredSeriesB.length - 1].data.displayDatetime
        );
        return latestDateB.getTime() - latestDateA.getTime(); // Descending order (latest first)
    });
};

const sortByLatestDateTable = (data) => {
    return data.sort((a, b) => {
        const filteredSeriesA = a.timeContextHeaders[0].date.filter((item) => item !== "");
        const filteredSeriesB = b.timeContextHeaders[0].date.filter((item) => item !== "");;
        // Check if either series is empty (no valid entries)
        if (!filteredSeriesA.length && !filteredSeriesB.length) {
            return 0; // No dates to compare, return equal
        } else if (!filteredSeriesA.length) {
            return 1; // Series A has no valid entries, sort it lower
        } else if (!filteredSeriesB.length) {
            return -1; // Series B has no valid entries, sort it lower
        }

        const latestDateA = new Date(filteredSeriesA.reduce((acc, date) => (new Date(date) > new Date(acc) ? date : acc), filteredSeriesA[0]))
        const latestDateB = new Date(filteredSeriesB.reduce((acc, date) => (new Date(date) > new Date(acc) ? date : acc), filteredSeriesB[0]))
        return latestDateB.getTime() - latestDateA.getTime(); // Descending order (latest first)
    });
};

const sortByLatestFilingDate = (data) => {
    return data.sort((a, b) => {
        // Filter timeseries entries where canDisplay AND isInDateRange are true
        const filteredSeriesA = a.series.filter((item) => item.data.canDisplay);
        const filteredSeriesB = b.series.filter((item) => item.data.canDisplay);
        // Check if either series is empty (no valid entries)
        if (!filteredSeriesA.length && !filteredSeriesB.length) {
            return 0; // No dates to compare, return equal
        } else if (!filteredSeriesA.length) {
            return 1; // Series A has no valid entries, sort it lower
        } else if (!filteredSeriesB.length) {
            return -1; // Series B has no valid entries, sort it lower
        }

        // Extract and compare the latest filingDate
        // There is no gurantee that the last data point is the latest one.
        const latestDateA = new Date(filteredSeriesA.reduce((acc, data) => (new Date(data.filingDate) > new Date(acc.filingDate) ? data : acc), filteredSeriesA[0]).filingDate)
        const latestDateB = new Date(filteredSeriesB.reduce((acc, data) => (new Date(data.filingDate) > new Date(acc.filingDate) ? data : acc), filteredSeriesB[0]).filingDate)
        return latestDateB.getTime() - latestDateA.getTime(); // Descending order (latest first)
    });
};

const sortByLatestFilingDateTable = (data) => {
    return data.sort((a, b) => {
        // Filter timeseries entries where canDisplay AND isInDateRange are true
        function getFilingDates(tableSeries) {
            const filingDates = [];
            for (let i = 0; i < tableSeries.length; i++) {
                
              for (let j = 0; j < tableSeries[i]['cell'].length; j++) {
                if (tableSeries[i]['cell'][j].provenance && tableSeries[i]['cell'][j].provenance.filingDate) {
                  filingDates.push(tableSeries[i]['cell'][j].provenance.filingDate);
                }
              }
            }
            return filingDates;
          }
        const filteredSeriesA = getFilingDates(a.tableSeries);
        const filteredSeriesB = getFilingDates(b.tableSeries);

        // Check if either series is empty (no valid entries)
        if (!filteredSeriesA.length && !filteredSeriesB.length) {
            return 0; // No dates to compare, return equal
        } else if (!filteredSeriesA.length) {
            return 1; // Series A has no valid entries, sort it lower
        } else if (!filteredSeriesB.length) {
            return -1; // Series B has no valid entries, sort it lower
        }

        const latestDateA = new Date(filteredSeriesA.reduce((acc, date) => (new Date(date) > new Date(acc) ? date : acc), filteredSeriesA[0]))
        const latestDateB = new Date(filteredSeriesB.reduce((acc, date) => (new Date(date) > new Date(acc) ? date : acc), filteredSeriesB[0]))

        return latestDateB.getTime() - latestDateA.getTime(); // Descending order (latest first)
    });
};

const sortByScore = (data) => {
    return data.sort(
        (a, b) => {
            if (a.score.aggregated > b.score.aggregated) { return -1 };
            if (a.score.aggregated < b.score.aggregated) { return 1 };
            return 0;
        }
    )
};


export function DataPane() {
    const defaultSortKey = "latestFilingDate";
    const defaultTimeSpan = "MAX";
    const defaultFormTypes = ["10-K", "10-Q"];

    const [accessToken, setAccessToken] = useState(null);

    const [apiError, setApiError] = useState(false);
    const [data, setData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [sectionHeaders, setSectionHeaders] = useState(null);
    const [sectionHeadersTable, setSectionHeadersTable] = useState(null);
    const [isDataEmpty, setIsDataEmpty] = useState(true);
    const [isTableDataEmpty, setIsTableDataEmpty] = useState(true);

    const [selectedForms, setselectedForms] = useState(defaultFormTypes);

    const handleClickFormType = (chip) => {
        setselectedForms((prevselectedForms) => {
            if (prevselectedForms.includes(chip)) {
                return prevselectedForms.filter((selectedChip) => selectedChip !== chip);
            } else {
                return [...prevselectedForms, chip];
            }
        });
    };

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const isMenuOpen = Boolean(menuAnchorEl);
    const handleMenuClick = (event) => {
      setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
      setMenuAnchorEl(null);
    };

    const [ticker, formType] = useContext(SearchBarContext);
    const [displayedCik, setDisplayedCik] = useContext(DisplayedCikContext);
    const [companyName, setCompanyName] = useContext(CompanyNameContext);
    const [exchangeName, setExchangeName] = useContext(ExchangeNameContext);
    const [wizardState, setWizardState] = useContext(OnboardingWizardContext);

    const [selectedTimeSeries, setSelectedTimeSeries] = useState(new Set());
    const [selectedTableSeries, setSelectedTableSeries] = useState(new Set());
    const [selectedDatapoint, setSelectedDatapoint] = useContext(SelectedDatapointContext);
    const [chartExpandState, setChartExpandState] = useState(ChartExpandStateContext);
    const [tableSeriesExpandState, setTableSeriesExpandState] = useState(TableSeriesExpandStateContext);

    const [filterValue, setFilterValue] = useState("");
    const [dataDisplayMask, setDataDisplayMask] = useState(new Set());
    const [tableDisplayMask, setTableDisplayMask] = useState(new Set());
    const [sortKey, setSortKey] = useState("");

    const [numDisplayableTimeSeries, setNumDisplayableTimeSeries] = useState(0);
    const [numDisplayableTableSeries, setNumDisplayableTableSeries] = useState(0);
    const [timeSpan, setTimeSpan] = useState(defaultTimeSpan);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    // For merge export feature
    const [splitButtonOpen, setSplitButtonOpen] = useState(false);
    const splitButtonAnchorRef = useRef(null);
    const [mergeDialogOpen, setMergeDialogOpen] = useState(false);
    const [mergeDialogSelection, setMergeDialogSelection] = useState(null);
    const [unitsErrorDialogOpen, setUnitsErrorDialogOpen] = useState(false);


    /********************************** Callbacks *************************************/

    function fillDateRange(data) {
        // console.log("=====fillDateRange")
        data.map(
            (ts, idx) => {
                // Mask datapoints outside of date range                    
                ts['series'] = ts['series'].map(
                    (dp) => {
                        const dpDate = new Date(dp['data']['displayDatetime'] + "T00:00:00");

                        // null date range means show everything
                        if (fromDate === null || toDate === null) {
                            dp['data']['isInDateRange'] = true;
                        } else {
                            dp['data']['isInDateRange'] = (fromDate <= dpDate && dpDate <= toDate);
                        }

                        return dp;
                    }
                )
                // Mask time series with no displayable datapoints
                const displayableTs = ts['series'].filter(
                    (dp) => {
                        return (dp['data']['isInDateRange'] && dp['data']['canDisplay'])
                    }
                );
                ts['shouldDisplay'] = displayableTs.length >= MIN_TS_LEN;

                //@@@ HACK: Filter out the flat time series that are likely due to model errors
                ts['shouldDisplay'] = ts['shouldDisplay'] && !displayableTs.every(
                    (dp) => {
                        return ["1.0", "0.0", "100.0"].includes(dp['data']['displayValue']);
                    }
                )


                return ts;
            }
        )
        return data;
    }

    const maskTimeSeriesWithDateRange = useCallback((prevState) => {
        // console.log("----------USE EFFECT")
        if (prevState !== undefined && prevState !== null) {
            const previousState = [...prevState];  // must do this?
            return fillDateRange(previousState);
        }
        return prevState;
    }, [fromDate, toDate]);


    /********************************** useEffects ************************************/

    // Runs once after initial render
    useEffect(
        () => {
            Auth.currentSession()
                .then(resp => setAccessToken(resp.getIdToken().getJwtToken()))
                .catch(error => { console.log(error); })
        },
        []
    );

    // Runs whenever accessToken changes
    useEffect(
        () => {
            if (ticker !== null) {
                const formTypes = ["10-K", "10-Q", "8-K"];
                const requestPayloads = formTypes.map(formType => ({
                    'ticker': ticker,
                    'form_type': formType,
                }));

                if (data !== undefined || data !== null) {
                    // Switch the tab to Text upon a new search
                    setTab(0)
                    setselectedForms(defaultFormTypes)
                    setData(null);
                    setTableData(null);
                    setIsDataEmpty(true);
                    setIsTableDataEmpty(true);
                    // setDisplayedCik(null);
                    setSelectedTimeSeries(new Set());
                    setSelectedTableSeries(new Set());
                    setSelectedDatapoint(null);
                    setChartExpandState([]);
                    setTableSeriesExpandState([]);
                    setFilterValue("");
                    setDataDisplayMask(new Set());
                    setTableDisplayMask(new Set());
                    setSortKey("");
                    setTimeSpan(defaultTimeSpan);
                    setSectionHeaders(null);
                    setSectionHeadersTable(null);
                    // setCompanyName(null);
                    // setExchangeName(null);
                }

                if (accessToken) {
                    Promise.all(requestPayloads.map(payload => 
                        makePostRequest(process.env.REACT_APP_API_BASE_URL + '/timeseries', accessToken, payload)
                    ))
                    .then(responses => {
                        let combinedData = [];
                        let combinedHeaders = [];
                                                
                        responses.forEach(response => {
                            let decodedPb = decodeTimeSeriesApiResponse(response['pb']);
                            
                            const headerInfo = decodedPb['orderedSections'];
                            decodedPb = getDataArrayWithSectionIdx(decodedPb['sections'], headerInfo);

                            combinedData = combinedData.concat(decodedPb);

                            // Unlike the hack for table section headers, time series have a fixed
                            // set of headers.  However, not all responses will return all headers.
                            // We want to take the longest list of headers.
                            combinedHeaders = combinedHeaders.length < headerInfo.length ? headerInfo : combinedHeaders;
                        });

                        setIsDataEmpty(Array.isArray(combinedData) && combinedData.length === 0);

                        // Set the chart titles here for everything else that needs it (keyword
                        // filter, chart display, export to excel)
                        combinedData.forEach((item, i) => {
                            // Build chart title
                            item['chartTitle'] = truncString(item['description'], 80);

                            if (item['unit'] || item['hint']) {
                                item['chartTitle'] = item['chartTitle'] + " (";

                                if (item['unit']) {
                                    item['chartTitle'] = item['chartTitle'] + item['unit'];
                                }

                                if (item['unit'] && item['hint']) {
                                    item['chartTitle'] = item['chartTitle'] + " ";
                                }

                                if (item["hint"]) {
                                    item['chartTitle'] = item['chartTitle'] + item['hint'];
                                }

                                item['chartTitle'] = item['chartTitle'] + ")";
                            }

                            item['shouldDisplay'] = true;
                        });

                        // Mask the time series to the user's requested date range
                        combinedData = fillDateRange(combinedData);
                        combinedData = getSortedData(combinedData, defaultSortKey, combinedHeaders)
                        setData(combinedData);
                        setApiError(false);
                        setSortKey(defaultSortKey);
                        setSectionHeaders(combinedHeaders);

                        // For setting the displayedCik context
                        // setDisplayedCik(cikInfo['cik']);
                        // setCompanyName(cikInfo['name']);
                        // setExchangeName(cikInfo['exchange']);

                        const startState = new Array(combinedData.length).fill(false);
                        const firstChartToDisplay = combinedData.findIndex(item => 
                            defaultFormTypes.includes(item.formType) && item.shouldDisplay === true
                          );
                        startState[firstChartToDisplay] = true;
                        setChartExpandState(startState);
                    })
                    .catch(
                        (error) => {
                            console.log(error);
                            setApiError(true);
                        }
                    );

                    Promise.all(requestPayloads.map(payload => 
                        makePostRequest(process.env.REACT_APP_API_BASE_URL + '/tableseries', accessToken, payload)
                    ))
                    .then(responses => {
                        let combinedTableData = [];
                        let combinedTableHeaders = [];
 
                        responses.forEach(response => {
                            let decodedPb = decodeTableSeriesApiResponse(response['pb']);
                            const headerInfo = decodedPb['orderedSections'];
                            decodedPb = getDataArrayWithSectionIdx(decodedPb['sections'], headerInfo);
                            // For each item in decodedPb increment the sectionIdx by the number of sections in the combinedTableHeaders
                            // HACK This is needed since the table doesn't return the predefined set of headers like KPI categories.
                            decodedPb.forEach((item) => {
                                item.sectionIdx += combinedTableHeaders.length;
                            });

                            combinedTableData = combinedTableData.concat(decodedPb);
                            combinedTableHeaders = combinedTableHeaders.concat(headerInfo);
                        });

                        // console.log(combinedTableHeaders);
                        setTableData(combinedTableData);
                        setIsTableDataEmpty(Array.isArray(combinedTableData) && combinedTableData.length === 0);
                        setSectionHeadersTable(combinedTableHeaders);
                        
                        
                        const startState = new Array(combinedTableData.length).fill(false);
                        const firstChartToDisplay = combinedTableData.findIndex(item => 
                            defaultFormTypes.includes(item.formType) && item.shouldDisplay === true
                          );
                        startState[firstChartToDisplay] = true;
                        setTableSeriesExpandState(startState);
                    })
                    .catch(
                        (error) => {
                            console.log(error);
                            setApiError(true);
                        }
                    );
                }
            } else {
                console.log("Null search");
            }
        },
        [accessToken, ticker]
    );

    useEffect (
        () => {
            if (selectedDatapoint) {
                const dpDate = selectedDatapoint?.dp?.data?.displayDatetime ? new Date(selectedDatapoint.dp.data.displayDatetime + "T00:00:00") : null;
                
                if (dpDate !== null && fromDate !== null && toDate !== null) {
                    const isInDateRange = (fromDate <= dpDate && dpDate <= toDate);
                    if (!isInDateRange) {
                        setSelectedDatapoint(null);
                    }
                }
            }
        },
        [fromDate, toDate]
    )


    // Run date filter on time series whenever data or fromDate or toDate change
    useEffect(
        () => {
            if (displayedCik) {
                setData(maskTimeSeriesWithDateRange);
            }
        },
        [displayedCik, fromDate, toDate, maskTimeSeriesWithDateRange]
        // displayedCik is a proxy dependency for when a new user query causes data to be updated
        // with the results of a new API call.  We can't put data in the dependency list because
        // it's an array and the underlying comparison to trigger useEffect doesn't do a deep
        // comparison.  Even if it did, this would cause an infinite loop. 
        //
        // However, we still can't make any changes to data inside this useEffect because then React
        // would require it to be a dependency.  The only way to do this is to set data as a
        // callback.                        
    )

    useEffect(
        () => {
            const newToDate = new Date();
            const newFromDate = new Date(newToDate);

            switch (timeSpan) {
                case "10Y":
                    newFromDate.setFullYear(newFromDate.getFullYear() - 10);
                    setFromDate(newFromDate);
                    setToDate(newToDate);
                    break;
                case "5Y":
                    newFromDate.setFullYear(newFromDate.getFullYear() - 5);
                    setFromDate(newFromDate);
                    setToDate(newToDate);
                    break;
                case "MAX":
                default:
                    setFromDate(null);
                    setToDate(null);
                    break;
            }
        },
        [timeSpan]
    );

    // Run whenever data or filterValue change
    useEffect(
        () => {
            if (data !== null) {
                let mask = new Set();
                data.map((d, idx) => { if (!d['chartTitle'].toLowerCase().includes(filterValue.toLowerCase()) || !selectedForms.some(prefix => d.formType.startsWith(prefix))) { mask.add(idx); } return null; });
                setDataDisplayMask(mask);
            }

            if (tableData !== null) {
                let tableMask = new Set();
                tableData.map((d, idx) => { if (!d['chartTitle'].toLowerCase().includes(filterValue.toLowerCase()) || !selectedForms.some(prefix => d.formType.startsWith(prefix))) { tableMask.add(idx); } return null; });
                setTableDisplayMask(tableMask);
            }
        }, [data, filterValue, tableData, selectedForms]
    )

    // Run whenever data, displayMask change
    useEffect(
        () => {
            let numDisplayableTimeSeries;
            let numDisplayableTableSeries;
            if (data !== null) {
                numDisplayableTimeSeries = data.filter((item, idx) => item.shouldDisplay && (!dataDisplayMask.has(idx) || item.section.toLowerCase().includes(filterValue.toLowerCase()) ||item.subSection.toLowerCase().includes(filterValue.toLowerCase()))).length;
                setNumDisplayableTimeSeries(numDisplayableTimeSeries);
            }

            if (tableData !== null) {
                numDisplayableTableSeries = tableData.filter((item, idx) => item.shouldDisplay && (!tableDisplayMask.has(idx) ||  item.section.toLowerCase().includes(filterValue.toLowerCase() || item.subSection.toLowerCase().includes(filterValue.toLowerCase())))).length;
                setNumDisplayableTableSeries(numDisplayableTableSeries);
            }
            if (data !== null  && tableData !== null) {
                // Check if only one tab has data
                if (
                    (numDisplayableTimeSeries > 0 && numDisplayableTableSeries === 0) ||
                    (numDisplayableTimeSeries === 0 && numDisplayableTableSeries > 0)
                ) {
                    setTab(numDisplayableTimeSeries > 0 ? 0 : 1); // Set value to the tab with data
                }
            }
        }, [data, tableData, dataDisplayMask, tableDisplayMask]
    )

    // Run whenever mergeDialogSelection is clicked
    useEffect(
        () => {
            if (mergeDialogSelection !== null) {
                let mergeInto = { ...data[mergeDialogSelection] };  // need a deep copy
                const idxToMerge = Array.from(selectedTimeSeries).filter((idx) => idx !== mergeDialogSelection);
                const tsToMerge = idxToMerge.map((idx) => data[idx])
                let result = mergeInto['series'];

                // Merge time series and sort by displayDate
                tsToMerge.forEach((ts) => { result = result.concat(ts['series']); })
                result.sort(
                    (a, b) => { // ascending sort
                        if (a.data.displayDatetime < b.data.displayDatetime) { return -1 };
                        if (a.data.displayDatetime > b.data.displayDatetime) { return 1 };
                        return 0;
                    }
                );
                mergeInto['series'] = result;

                // if the date range is MAX, then fromDate and toDate are null, so we have to figure out the
                // min and max dates of the time series being exported and use those instead.
                let minDate = fromDate;
                let maxDate = toDate;
                if (minDate === null || maxDate === null) {
                    const dates = mergeInto['series'].filter(
                        (dp) => { return dp['data']['canDisplay'] && dp['data']['isInDateRange'] }
                    ).map((dp) => { return dp['data']['displayDatetime'] })
                    dates.sort()

                    minDate = datefnstz_toDate(dates[0] + "T00:00:00", { timeZone: 'America/New_York' }) ?? fromDate;
                    maxDate = datefnstz_toDate(dates[dates.length - 1] + "T00:00:00", { timeZone: 'America/New_York' }) ?? toDate;
                }

                const filename = `${ticker}_${formType}_${minDate.toISOString().split('T')[0]}_${maxDate.toISOString().split('T')[0]}.xlsx`;
                exportXlsxWorkbook(filename, ticker, exchangeName, companyName, minDate, maxDate, [mergeInto]);
                ampli.mergeDataExport(
                    {
                        "Export Details": [
                            ticker,
                            mergeInto['formType'],
                            mergeInto['docType'].split('.')[0],
                            data[mergeDialogSelection]['description'],
                            tsToMerge.map(ts => ts.description),
                            minDate.toISOString().split('T')[0],
                            maxDate.toISOString().split('T')[0]
                        ]
                    }
                );
                setMergeDialogSelection(null);  // must clear it
            };
        }, [mergeDialogSelection]   // There are lots of dependencies that we don't include here on purpose.  
        // Including them will cause downloads whenever those deps change. 
    )


    // Run whenever sortKey changes
    function getSortedData(data, sortKey, sectionHeaders) {
        if (!data) { // Handle null or undefined data
            return []; // Or throw an error, depending on your needs
        }

        let sortedData = sortBy(sortBy(data.slice(), 'modifier').slice(), "chartTitle").slice();

        switch (sortKey) {
            case "latestDate":
            sortedData = sortByLatestDate(sortedData.slice());
            break;
            case "length":
            sortedData = sortBySeriesLength(sortedData.slice());
            break;
            case "latestFilingDate":
            sortedData = sortByLatestFilingDate(sortedData.slice());
            break;
            case "score":
            sortedData = sortByScore(sortedData.slice());
            break;
            default:
            sortedData = sortBy(sortedData.slice(), sortKey);
        }
        const sectionHeadersWithIndex = sectionHeaders.map(
            (section, index) => (
                { index, name: section }
            )
        ).concat({ index: -1, name: "Other" });

        sortedData = sectionHeadersWithIndex.map(
            (section) => {
                return sortedData.filter((ts, idx) => ts.sectionIdx === section.index);
            }
        ).flat();

        return sortedData;
    }

    useEffect(
        () => {
            if (data !== null) {
                let sortedData = getSortedData(data, sortKey, sectionHeaders)

                // Need to recompute selected timseries too!
                const newSelectedTimeSeries = new Set(sortedData.filter((item, index) => selectedTimeSeries.has(data.indexOf(item))).map((item) => sortedData.indexOf(item)));
                setSelectedTimeSeries(newSelectedTimeSeries);
                setData(sortedData);
            }

            
            if (tableData !== null) {
                let sortedData = sortBy(tableData.slice(), "chartTitle").slice();
                if (sortKey === "latestDate") {
                    sortedData = sortByLatestDateTable(sortedData.slice());
                } else if (sortKey === "length") {
                    sortedData = sortBySeriesLengthTable(sortedData.slice());
                } else if (sortKey === "latestFilingDate") {
                    sortedData = sortByLatestFilingDateTable(sortedData.slice());
                } else if (sortKey === "score") {
                    sortedData = sortByScore(sortedData.slice());
                }
                
                const sectionHeadersTableWithIndex = sectionHeadersTable.map(
                    (section, index) => (
                        { index, name: section }
                    )
                ).concat({ index: -1, name: "Other" });

                sortedData = sectionHeadersTableWithIndex.map(
                    (section) => {
                        return sortedData.filter((ts, idx) => ts.sectionIdx === section.index);
                    }
                ).flat();

                // Need to recompute selected timseries too!
                const newSelectedTableSeries = new Set(sortedData.filter((item, index) => selectedTableSeries.has(tableData.indexOf(item))).map((item) => sortedData.indexOf(item)));
                setSelectedTableSeries(newSelectedTableSeries);
                setTableData(sortedData);
            }


        }, [sortKey]    // We purposefully do not add data or selectedTimeSeries here as deps. Adding data will cause infinite
        // rerenders.  It is OK because data only ever changes when we make an API call and that
        // forces a reset of sortkey anyway.  In reality, we should be using an
        // updater function inside, i.e., setData(prevData => ...)
    );

    /********************************** Event handlers ************************************/

    const onExportButtonClick = () => {
        let selectedIdx;
        let timeSeriesToExport;
        let tableSeriesToExport;
        if (tab === 0) { 
            selectedIdx = Array.from(selectedTimeSeries);
            selectedIdx.sort();
            timeSeriesToExport = selectedIdx.map((idx) => data[idx]);
        } else {
            selectedIdx = Array.from(selectedTableSeries);
            selectedIdx.sort();
            tableSeriesToExport = selectedIdx.map((idx) => tableData[idx]);
        }

        // if the date range is MAX, then fromDate and toDate are null, so we have to figure out the
        // min and max dates of the time series being exported and use those instead.
        let minDate = fromDate;
        let maxDate = toDate;
        if ((minDate === null || maxDate === null) & tab === 0) {
            const dates = timeSeriesToExport.map(
                (ts) => {
                    return ts['series'].filter(
                        (dp) => { return dp['data']['canDisplay'] && dp['data']['isInDateRange'] }
                    ).map((dp) => { return dp['data']['displayDatetime'] })
                }
            ).flat()
            dates.sort()

            minDate = datefnstz_toDate(dates[0] + "T00:00:00", { timeZone: 'America/New_York' }) ?? fromDate;
            maxDate = datefnstz_toDate(dates[dates.length - 1] + "T00:00:00", { timeZone: 'America/New_York' }) ?? toDate;
        }

        if ((minDate === null || maxDate === null) & tab === 1) {
            const dates = tableSeriesToExport.map(
                (ts) => {
                    return ts['timeContextHeaders'][0].date.slice(1) // The first one is always empty
                }
            ).flat()
            dates.sort()

            minDate = datefnstz_toDate(dates[0] + "T00:00:00", { timeZone: 'America/New_York' }) ?? fromDate;
            maxDate = datefnstz_toDate(dates[dates.length - 1] + "T00:00:00", { timeZone: 'America/New_York' }) ?? toDate;
        }

        const filename = `${ticker}_${formType}_${minDate.toISOString().split('T')[0]}_${maxDate.toISOString().split('T')[0]}.xlsx`;
        
        if (tab == 0) {
            exportXlsxWorkbook(filename, ticker, exchangeName, companyName, minDate, maxDate, timeSeriesToExport);
        } else {

            exportTableXlsxWorkbook(filename, ticker, exchangeName, companyName, minDate, maxDate, tableSeriesToExport);
        }
        

        advanceWizardState(6, wizardState, setWizardState);
        //setWizardCookie(false);
    };

    // debounce function
    function debounce(func, wait = 500) {
        let timeout;
        function debounced(...args) {
            const later = () => {
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        }

        debounced.clear = () => {
            clearTimeout(timeout);
        };

        return debounced;
    }

    const debouncedHandleKeywordSearch = useMemo(
        () => debounce(handleKeywordSearchQuery, 1000),
        [],
    );

    // Function to send Amplitude event
    function handleKeywordSearchQuery(query) {
        const fd = new Date(fromDate).toLocaleDateString('en-US');
        const td = new Date(toDate).toLocaleDateString('en-US');
        ampli.keywordSearch({ "Query Date Range": [fd, td], "Query Form Type": formType, "Query Symbol": ticker, "Keyword": query });
    }

    const onKeywordFilterTimeSeries = (event) => {
        setFilterValue(event.target.value);
        debouncedHandleKeywordSearch(event.target.value);
    }

    // Sort selection change
    const handleSortChange = (event) => {
        // Collapse all the KPIs and clear out the provenance pane:
        const startState = new Array(chartExpandState).fill(false);
        setChartExpandState(startState);
        setSelectedDatapoint(null);

        setSortKey(event.target.value);
        ampli.sort({ "Sort Type": event.target.value });
        //handleSort(event.target.value);
    };

    const handleTimeSpanChange = (event, state) => {
        if (state !== null) {
            setTimeSpan(state);
            ampli.timeSpanSelection({ "Time Span": state });
        }
    }

    //------ Merge download handling -------------------------------------------
    const handleSplitButtonToggle = () => {
        setSplitButtonOpen((prev) => { return !prev });
    }

    const handleSplitButtonClose = () => {
        setSplitButtonOpen(false);
    }

    const handleMergeDialogClose = () => {
        setMergeDialogOpen(false);
    }

    const handleMergeDialogSelect = (idx) => {
        setMergeDialogSelection(idx);
        setMergeDialogOpen(false);
    }

    const onMergeExportClick = () => {
        const tsList = Array.from(selectedTimeSeries);

        // Check units are compatible
        const units = Array.from(new Set(tsList.map(
            (ts) => {
                return data[ts]['unit'];
            }
        )));

        if (units.length > 1) {
            setUnitsErrorDialogOpen(true);
        } else {
            const selectionLabels = Array.from(new Set(tsList.map(
                (ts) => {
                    return data[ts]['chartTitle'] + (data[ts]['modifier'] ? " -- for " + data[ts]['modifier'] : "");
                }
            )));

            if (selectionLabels.length > 1) {
                setMergeDialogOpen(true);
            } else {
                setMergeDialogSelection(tsList[0]);
            }
        }
        setSplitButtonOpen(false);
    }

    const [tab, setTab] = useState(0);

    const handleChange = (event, newValue) => {
        if (newValue == null) {
            return 
        }
        setTab(newValue);
        advanceWizardState(3, wizardState, setWizardState);

        // If we're switching to tables, fully close down the chart 
        // workspace:
        if(newValue === 1) {            
            const startState = new Array(chartExpandState).fill(false);
            setChartExpandState(startState);
            setSelectedDatapoint(null);
        }
            
        ampli.tabClick({ "KPI Source Type": newValue === 0 ? "Text" : "Tables" });
    };




    /***********************************************************************************************/

    return (
        <div name="DataPane">
            <Dialog
                PaperProps={{ sx: { backgroundColor: "var(--mui-palette-error-light)", borderRadius: "16px", padding: 5 } }}
                open={unitsErrorDialogOpen}
                onClose={() => { setUnitsErrorDialogOpen(false) }}>
                <DialogContentText><Typography variant="h6" sx={{ color: "var(--mui-palette-primary-contrastText)" }}>The selected time series have different units and cannot be merged.</Typography></DialogContentText>
            </Dialog>
            <Dialog
                PaperProps={{ sx: { borderStyle: "solid", borderWidth: "5px", borderColor: "var(--brand-main)", borderRadius: "16px", padding: 5 } }}
                open={mergeDialogOpen}
                maxWidth="md"
                onClose={handleMergeDialogClose}>
                <DialogTitle sx={{ padding: 0, textAlign: "left" }}>Please select the time series to merge into:</DialogTitle>
                <List sx={{ pt: 0 }}>
                    {Array.from(selectedTimeSeries).map((idx) => (
                        <ListItem disableGutters key={idx}>
                            <ListItemButton onClick={() => handleMergeDialogSelect(idx)}>
                                <ListItemIcon><LabelIcon size="small" /></ListItemIcon><ListItemText primary={data[idx]['chartTitle'] + (data[idx]['modifier'] ? " -- for " + data[idx]['modifier'] : "")} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Dialog>

            <Container disableGutters maxWidth="false" mx="auto" sx={{ borderRight: 1, borderColor: "grey.800" }}>
                <Stack>

                    <Paper sx={{ pb: "5px", borderBottom: 0, borderRadius: 0, borderColor: "grey.500" }}>
                        <Stack>
                            {/* <Typography sx={{ height: "35px", lineHeight: '1.0', ml: "10px", mt: 0.5, color: "var(--brand-main)" }} variant="h4">{companyName}</Typography>
                            <Typography sx={{ height: "15px", ml: "10px", mb: 2 }} variant="h6">{exchangeName ? exchangeName + ': ' + ticker.toUpperCase() : ''}</Typography> */}
                            <Grid container sx={{ border: "", borderColor: "#ffffff", borderWidth: "1px", ml: "10px", mr: "10px" }}>
                                <Grid xs={2.5} container justifyContent="left" sx={{ border: "", borderColor: "#ffffff", borderWidth: "1px" }} pr={1}>
                                    {/* <TextField
                                        // sx={{ml: '10px', mr: "10px"}}
                                        placeholder="Keyword or (unit)"
                                        disabled={isDataEmpty && isTableDataEmpty}
                                        fullWidth
                                        size="small"
                                        onChange={onKeywordFilterTimeSeries}
                                        onFocus={(e) => { e.stopPropagation() }}
                                        value={filterValue}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon sx={{color: "var(--brand-main)"}}/>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (filterValue !== "") && (
                                                <IconButton
                                                    onClick={() => { setFilterValue(""); setDataDisplayMask(new Set()); setTableDisplayMask(new Set()); }}
                                                ><ClearIcon /></IconButton>
                                            ),
                                        }}
                                    /> */}
                                </Grid>
                                <Grid xs={2} container justifyContent="left" sx={{ border: "", borderColor: "#ffffff", borderWidth: "1px" }} pr={1}>
                                    {/* <FormControl fullWidth size="small" >
                                        <InputLabel id="sort-by-label">Sort By</InputLabel>
                                        <Select
                                            labelId="sort-by-label"
                                            id="sort-by"
                                            value={sortKey}
                                            label="Sort By"
                                            onChange={handleSortChange}
                                        >
                                            <MenuItem value="chartTitle">Name (A - Z)</MenuItem>
                                            <MenuItem value="unit">Units (A - Z)</MenuItem>
                                            <MenuItem value="latestFilingDate">Most recent filing</MenuItem>
                                        </Select>
                                    </FormControl> */}
                                </Grid>
                                <Grid xs={2} container justifyContent="left" sx={{ border: "", borderColor: "#ffffff", borderWidth: "1px" }} pr={1}>
                                    {/* <ToggleButtonGroup fullWidth exclusive value={timeSpan} onChange={handleTimeSpanChange} size="small">
                                        <ToggleButton value="5Y">5Y</ToggleButton>
                                        <ToggleButton value="10Y">10Y</ToggleButton>
                                        <ToggleButton value="MAX">MAX</ToggleButton>
                                    </ToggleButtonGroup> */}
                                </Grid>
                                <Grid xs={5.5} container sx={{ border: "", borderColor: "#ffffff", borderWidth: "1px", justifyContent: "flex-end" }}>
                                    {/* <ButtonGroup variant="contained" ref={splitButtonAnchorRef}>
                                        <Tooltip title={selectedTimeSeries.size > 1 && <h3>You can merge your selections into a single time series before exporting by clicking the arrow to the right.</h3>}>
                                            <span>
                                                <OnboardingWizard step={7} placement="bottom">
                                                    <Button variant='contained' sx={{ height: "100%", mr: "1px" }} display="flex" onClick={onExportButtonClick} disabled={((isDataEmpty || selectedTimeSeries.size === 0) && tab === 0)|| ((isTableDataEmpty || selectedTableSeries.size === 0) && tab === 1) }>
                                                        <DownloadIcon sx={{ mr: "5px" }} />Export selections to Excel
                                                    </Button>
                                                </OnboardingWizard>
                                            </span>
                                        </Tooltip>
                                        <Button size="small" disabled={isDataEmpty || selectedTimeSeries.size < 2} onClick={handleSplitButtonToggle}><ArrowDropDownIcon /></Button>
                                    </ButtonGroup>
                                    <Popper sx={{ zIndex: 1, p: '2px' }} placement="bottom-end" anchorEl={splitButtonAnchorRef.current} open={splitButtonOpen} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow {...TransitionProps}>
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleSplitButtonClose}>
                                                        <   Button variant="contained" onClick={onMergeExportClick}>Merge selections, then export to excel</Button>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper> */}
                                </Grid>
                            </Grid>
                        </Stack>
                    </Paper>
                    {/* <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth" sx={{
                        // "& button.Mui-selected": { backgroundColor: "#1e1e1e" },
                    }}
                        TabIndicatorProps={{
                            sx: {
                                top: 0
                            }
                        }}
                    > */}
                        {/* <Tab label={`Reported in Text (${numDisplayableTimeSeries})`} {...a11yProps(0)}  disabled={numDisplayableTimeSeries === 0} />                        
                        <Tab label={`Reported in Tables (${numDisplayableTableSeries})`} {...a11yProps(1)}  disabled={numDisplayableTableSeries === 0}/> */}
                        {/* <OnboardingWizard step={4} placement="right"><div></div></OnboardingWizard> */}
                        {/* We cannot wrap Tab because Tabs expect children to be Tab components */}
                    {/* </Tabs> */}
                    <Card variant="outlined" sx={{ml: 1, mb:1, mr:1, p: 1}}>
                        <Stack direction="row" spacing={1}  justifyContent="center" alignItems="center">
                            <TextField
                                placeholder="Keyword or (unit)"
                                disabled={isDataEmpty && isTableDataEmpty}
                                fullWidth
                                size="small"
                                onChange={onKeywordFilterTimeSeries}
                                onFocus={(e) => { e.stopPropagation() }}
                                value={filterValue}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{color: "var(--brand-main)"}}/>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (filterValue !== "") && (
                                        <IconButton
                                            onClick={() => { setFilterValue(""); setDataDisplayMask(new Set()); setTableDisplayMask(new Set()); }}
                                        ><ClearIcon /></IconButton>
                                    ),
                                }}
                            />
                            {['10-K', '10-Q', '8-K'].map((chip) => (
                                <Chip
                                    key={chip}
                                    label={chip}
                                    size="medium"
                                    variant={'outlined'}
                                    onClick={() => handleClickFormType(chip)}
                                    sx={{
                                        ...(selectedForms.includes(chip) && {
                                            color: 'var(--brand-main)',
                                            backgroundColor: '#222b33',
                                            fontWeight: "700"
                                        })
                                    }}
                                />
                            ))}
                            <ToggleButtonGroup
                                value={tab}
                                exclusive
                                onChange={handleChange}
                                aria-label="text alignment"
                                size="small"
                            >
                                <ToggleButton value={0} aria-label="text" sx={{'&.Mui-selected': {backgroundColor: '#222b33 !important'}}} >
                                    Text
                                </ToggleButton>
                                
                                <ToggleButton value={1} aria-label="tables" sx={{'&.Mui-selected': {backgroundColor: '#222b33 !important'}}}>
                                    Tables
                                    <OnboardingWizard step={3} placement="right"><div></div></OnboardingWizard>
                                </ToggleButton>
                                
                                
                            </ToggleButtonGroup>
                            <ButtonGroup variant="contained" ref={splitButtonAnchorRef}>
                                <Tooltip title={selectedTimeSeries.size > 1 && <h3>You can merge your selections into a single time series before exporting by clicking the arrow to the right.</h3>}>
                                    <span>
                                        <OnboardingWizard step={6} placement="bottom">
                                            <Button variant='contained' sx={{ height: "100%", mr: "1px" }} display="flex" onClick={onExportButtonClick} disabled={((isDataEmpty || selectedTimeSeries.size === 0) && tab === 0)|| ((isTableDataEmpty || selectedTableSeries.size === 0) && tab === 1) }>
                                                <DownloadIcon sx={{ mr: "5px" }} />Export
                                            </Button>
                                        </OnboardingWizard>
                                    </span>
                                </Tooltip>
                                <Button size="small" disabled={isDataEmpty || selectedTimeSeries.size < 2} onClick={handleSplitButtonToggle}><ArrowDropDownIcon /></Button>
                            </ButtonGroup>
                            <Popper sx={{ zIndex: 1, p: '2px' }} placement="bottom-end" anchorEl={splitButtonAnchorRef.current} open={splitButtonOpen} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow {...TransitionProps}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleSplitButtonClose}>
                                                <   Button variant="contained" onClick={onMergeExportClick}>Merge selections, then export to excel</Button>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                            <IconButton onClick={handleMenuClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={menuAnchorEl}
                                open={isMenuOpen}
                                onClose={handleMenuClose}
                                slotProps={{
                                    paper: {
                                      style: {
                                        maxHeight: 48 * 4.5,
                                        width: '20ch',
                                      },
                                    },
                                  }}
                            >
                                <MenuItem>
                                    <FormControl fullWidth size="small" >
                                        <InputLabel id="sort-by-label">Sort By</InputLabel>
                                        <Select
                                            labelId="sort-by-label"
                                            id="sort-by"
                                            value={sortKey}
                                            label="Sort By"
                                            onChange={handleSortChange}
                                        >
                                            <MenuItem value="chartTitle">Name (A - Z)</MenuItem>
                                            <MenuItem value="unit">Units (A - Z)</MenuItem>
                                            <MenuItem value="latestFilingDate">Most recent filing</MenuItem>
                                        </Select>
                                    </FormControl>
                                </MenuItem>
                                <MenuItem>
                                    <ToggleButtonGroup fullWidth exclusive value={timeSpan} onChange={handleTimeSpanChange} size="small">
                                        <ToggleButton value="5Y">5Y</ToggleButton>
                                        <ToggleButton value="10Y">10Y</ToggleButton>
                                        <ToggleButton value="MAX">MAX</ToggleButton>
                                    </ToggleButtonGroup>
                                </MenuItem>
                            </Menu>
                        </Stack>
                    </Card>
                    <SelectedTimeSeriesContext.Provider value={[selectedTimeSeries, setSelectedTimeSeries]}>
                        <CustomTabPanel value={tab} index={0}>
                            <Box sx={{ height: "calc(100vh - 15px - 67px - 61px - 120px - 48px)", maxHeight: "calc(100vh - 15px - 67px - 61px - 120px)", overflowY: "scroll" }} align="center" alignItems="center" justifyContent="center">
                                {
                                    apiError
                                        ?
                                        <Box width="50%" maxWidth="50%" height="100%" align="center" alignItems="center" display="flex" justifyContent="center">
                                            <Alert severity="error" variant="filled" size="md">An error occured during your search.  Please check your search parameters and try again.</Alert>
                                        </Box>
                                        :
                                        <DateRangeContext.Provider value={[fromDate, toDate]}>
                                            <ChartExpandStateContext.Provider value={[chartExpandState, setChartExpandState]}>
                                                <ChartList
                                                    data={data}
                                                    displayMask={dataDisplayMask}
                                                    filterValue={filterValue}
                                                    sectionHeaders={sectionHeaders} />
                                            </ChartExpandStateContext.Provider>
                                        </DateRangeContext.Provider>
                                }
                            </Box>
                        </CustomTabPanel>
                    </SelectedTimeSeriesContext.Provider>
                    <SelectedTableSeriesContext.Provider value={[selectedTableSeries, setSelectedTableSeries]}>
                        <CustomTabPanel value={tab} index={1}>
                            <Box sx={{ height: "calc(100vh - 15px - 67px - 61px - 120px - 48px)", maxHeight: "calc(100vh - 15px - 67px - 61px - 120px)", overflowY: "scroll" }} align="center" alignItems="center" justifyContent="center">
                                <DateRangeContext.Provider value={[fromDate, toDate]}>
                                    <TableSeriesExpandStateContext.Provider value={[tableSeriesExpandState, setTableSeriesExpandState]}>
                                        <TableList
                                            data={tableData}
                                            displayMask={tableDisplayMask}
                                            filterValue={filterValue}
                                            sectionHeaders={sectionHeadersTable} />
                                    </TableSeriesExpandStateContext.Provider>
                                </DateRangeContext.Provider>
                            </Box>
                        </CustomTabPanel>
                    </SelectedTableSeriesContext.Provider>
                </Stack>
            </Container>
        </div>
    );
}

export default DataPane;
