import React, { useState, useEffect } from 'react';
import { Card, Container, Grid, Box, Typography, List, ListItem, ListItemText, ListItemButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { makeGetRequest, makePostRequest } from 'revelata-common-ui';
import Skeleton from '@mui/material/Skeleton';
import { findCompanyAndFormatName } from 'revelata-common-ui'

const MAX_RECENT_SEARCHES = 7;

const RecentSearch = () => {
    const [recentSearches, setRecentSearches] = useState([]);
    const [accessToken, setAccessToken] = useState(null);
    const [companyInfo, setCompanyInfo] = useState();


    const { user } = useAuthenticator((context) => [context.user]);

    useEffect(() => {
        Auth.currentSession()
            .then(resp => setAccessToken(resp.getIdToken().getJwtToken()))
            .catch(error => { console.log(error); });
    }, []);

    useEffect(() => {
        if (user && user.username) {
            makeGetRequest(process.env.REACT_APP_API_BASE_URL + `/search_history/${user.username}`, accessToken)
                .then(data => {
                    const sortedSearches = data.search_history.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                    setRecentSearches(sortedSearches);
                    
                    const tickers = sortedSearches
                        .slice(0, MAX_RECENT_SEARCHES)
                        .map(search => search.ticker);
                    const companyInfoPromises = tickers.map(ticker => findCompanyAndFormatName(ticker));

                    Promise.all(companyInfoPromises)
                        .then(companies => {
                            const info = {};
                            companies.forEach(company => {
                                info[company.ticker] = company.name;
                            });
                            setCompanyInfo(info);
                        })
                        .catch(error => console.log(error));
                    
                    
                })
                .catch(error => console.log(error));
        }
    }, [user, accessToken]);

    

    console.log(`${user.username} has ${recentSearches.length} recent searches`);


    return (
        <Container maxWidth="lg">
            <Grid container justifyContent="center">
                <Grid item xs={12} md={12} lg={12}>
                    <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle1" sx={{ pt: 0, m: 0, textAlign: "left", color: "var(--brand-main)", fontSize: "1.0rem", fontWeight: 'bold' }}>Recent Searches</Typography>
                        <Card variant="outlined" sx={{ mt: 1, minHeight: 200, display: 'flex', flexDirection: 'column' }}>
                            {companyInfo === undefined ? (
                                <Skeleton sx={{ flexGrow: 1, bgcolor: 'grey.900' }}  variant="rectangular" />
                            ) : (
                                <List>
                                    {recentSearches.slice(0, MAX_RECENT_SEARCHES).map((search, index) => (
                                        companyInfo[search.ticker] && (
                                            <ListItem disablePadding key={index}>
                                                <ListItemButton onClick={() => window.location.href = `?ticker=${search.ticker}&formType=10-K`}>
                                                    <ListItemText>
                                                        <Typography sx={{ fontSize: "0.75rem", fontWeight: "700", cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}>
                                                            {companyInfo[search.ticker]} ({search.ticker})
                                                        </Typography>
                                                    </ListItemText>
                                                    <ArrowForwardIosIcon fontSize='small' />
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    ))}
                                </List>
                            )}
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default RecentSearch;
