import { useState, useEffect} from 'react';

import { Auth } from 'aws-amplify';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActionArea
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import 'swiper/css';
import 'swiper/css/navigation';
import { SP1500 } from 'revelata-common-ui';
import { makePostRequest } from 'revelata-common-ui';
import Skeleton from '@mui/material/Skeleton';

// Custom styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
    // Add any specific card styling here
    // Example: rounded corners, shadows, etc.
    borderRadius: 12,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
}));

// Custom styled components
const NavigationButton = styled('div')(({ theme }) => ({
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    cursor: 'pointer',
    '&.swiper-button-disabled': {
        opacity: 0,
        cursor: 'default',
    },
    '& svg': {
        fontSize: 20,
        color: 'var(--mui-palette-grey-600)',
    }
}));

const CustomSwiper = styled(Swiper)({
    '& .swiper-button-prev, & .swiper-button-next': {
        '&::after': {
            display: 'none',
        }
    },
});



const Comps = ({ ticker }) => {
    const [accessToken, setAccessToken] = useState(null);
    const [apiError, setApiError] = useState(false);
    const [comps, setComps] = useState([]);
    const [uniqueDimensions, setUniqueDimensions] = useState([]);
    const chunk = (arr, size) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
            arr.slice(i * size, i * size + size)
        );
    // Runs once after initial render
    useEffect(
        () => {
            Auth.currentSession()
                .then(resp => setAccessToken(resp.getIdToken().getJwtToken()))
                .catch(error => { console.log(error); })
        },
        []
    );


    // Runs whenever accessToken changes
    useEffect(
        () => {
            if (ticker !== null) {
                const requestPayload = {
                    'ticker': ticker,
                };

                if (accessToken) {
                    makePostRequest(process.env.REACT_APP_API_BASE_URL + '/comp', accessToken, requestPayload)
                        .then(response => {
                            console.log("response", response)
                            response.forEach(comp => {
                                if (comp.dimensions.length === 0) {
                                    comp.dimensions = ["Other peers"];
                                }
                            });
                            setComps(response)
                            setApiError(false);
                            const uniqueDimensions = [...new Set(response.flatMap(comp => comp.dimensions))];
                            uniqueDimensions.sort((a, b) => b.localeCompare(a));
                            setUniqueDimensions(uniqueDimensions);

                        })
                        .catch(
                            (error) => {
                                console.log(error);
                                setApiError(true);
                            }
                        )
                }
            }
        },
        [accessToken, ticker]
    );



    return (
        <Box sx={{ width: '100%', minWidth: '100%', margin: 'auto', pt: 9, pr: 5 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '1.0rem', color: "var(--brand-analogous)", pb: 1, pl: 5 }}>
                Comparable Companies
            </Typography>
            <Box sx={{ width: '100%' }}>
                {comps.length === 0 && <Skeleton height={20} sx={{ m: 4, p: 0 }} />}
                <Grid >
                    {uniqueDimensions.map((dimension, index) => (
                        <Box key={index} pb={2}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem', color: "var(--brand-analogous-50)", pb: 1, pl: 5 }}>
                                {dimension}
                            </Typography>
                            <CustomSwiper
                                modules={[Navigation]}
                                navigation={{
                                    prevEl: '.swiper-button-prev',
                                    nextEl: '.swiper-button-next',
                                }}
                                spaceBetween={20}
                                slidesPerView={1}
                                style={{
                                    paddingLeft: "36px",
                                    paddingRight: "36px"
                                }}
                            >
                                <NavigationButton className="swiper-button-prev">
                                    <ChevronLeftIcon />
                                </NavigationButton>
                                <NavigationButton className="swiper-button-next">
                                    <ChevronRightIcon />
                                </NavigationButton>
                                {chunk(comps.filter(comp => comp.dimensions.includes(dimension)), 4).map((group, idx) => (
                                    <SwiperSlide key={idx}>
                                        <Grid container spacing={2}>
                                            {group.map((comp) => (
                                                <Grid item xs={3} key={comp.ticker}>
                                                    <StyledCard variant="outlined">
                                                        <CardActionArea
                                                            onClick={() => {
                                                                if (SP1500.some(item => item.ticker === comp.ticker)) {
                                                                    window.location.href = `?ticker=${comp.ticker}&formType=10-K`;
                                                                }
                                                            }}
                                                        >
                                                            <CardContent sx={{ p: 0, m: 1 }}>
                                                                <Typography sx={{ fontSize: "10px", }} color={"var(--brand-main)"} fontWeight={"bold"}>
                                                                    {comp.ticker}
                                                                </Typography>
                                                                <Typography component="div" gutterBottom
                                                                    sx={{
                                                                        fontSize: "10px",
                                                                        display: "-webkit-box",
                                                                        WebkitLineClamp: "1",
                                                                        WebkitBoxOrient: "vertical",
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }}
                                                                >
                                                                    {comp.name}
                                                                </Typography>
                                                                <Typography fontSize={"9px"} color="text.secondary">
                                                                    {SP1500.some(item => item.ticker === comp.ticker) ? 'S&P 1500' : 'Coming Soon!'}
                                                                </Typography>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </StyledCard>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </SwiperSlide>
                                ))}
                            </CustomSwiper>
                        </Box>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
};

export default Comps;