import { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';

import { Auth } from 'aws-amplify';

import {
    Grid,
    Stack,
    Typography,
    Collapse,
    Card,
    IconButton,
    Button
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LinearProgress from '@mui/material/LinearProgress';

import { makeGetRequest, makePostRequest } from 'revelata-common-ui';
import { DisplayedCikContext, SearchBarContext, CompanyNameContext, ExchangeNameContext } from './Contexts';
import { set } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import WatchlistButton from './WatchlistButton';
import Skeleton from '@mui/material/Skeleton';
import Comps from './Comps'

export function CompanyInfoPane() {
    const [accessToken, setAccessToken] = useState(null);

    const [apiError, setApiError] = useState(false);
    const [companySummary, setCompanySummary] = useState(null);
    const [comapnySegmentSummary, setCompanySegmentSummary] = useState(null);

    const [ticker, formType] = useContext(SearchBarContext);
    const [displayedCik, setDisplayedCik] = useContext(DisplayedCikContext);
    const [companyName, setCompanyName] = useContext(CompanyNameContext);
    const [exchangeName, setExchangeName] = useContext(ExchangeNameContext);
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    // Runs once after initial render
    useEffect(
        () => {
            Auth.currentSession()
                .then(resp => setAccessToken(resp.getIdToken().getJwtToken()))
                .catch(error => { console.log(error); })
        },
        []
    );

    // Runs whenever accessToken changes
    useEffect(
        () => {
            if (ticker !== null) {
                const requestPayload = {
                    'ticker': ticker,
                };

                if (companyName !== undefined || companyName !== null) {
                    setDisplayedCik(null);
                    setCompanyName(null);
                    setExchangeName(null);
                }

                if (accessToken) {
                    makePostRequest(process.env.REACT_APP_API_BASE_URL + '/company_info', accessToken, requestPayload)
                        .then(response => {
                            setApiError(false);

                            // For setting the displayedCik context
                            setDisplayedCik(response['cik']);
                            setCompanyName(response['name']);
                            setExchangeName(response['exchange']);
                        })
                        .catch(
                            (error) => {
                                console.log(error);
                                setApiError(true);
                            }
                        )
                }
            }
        },
        [accessToken, ticker]
    );

    useEffect(
        () => {
            if (displayedCik !== null) {
                const headers = new Headers();
                fetch(
                    process.env.REACT_APP_STATIC_WWW_URL + '/company_summaries/' + displayedCik + '.summary.md',
                    {
                        method: 'GET',
                        headers: headers //{'Authorization': accessToken},
                    }
                )
                    .then(
                        response => {
                            return response.text();
                            //setCompanySummary(response.text());
                        }
                    )
                    .then(
                        data => {
                            setCompanySummary(data);
                        }

                    ).catch(
                        error => {
                            console.log(error);
                        }
                    )

                fetch(process.env.REACT_APP_STATIC_WWW_URL + '/company_summaries/' + displayedCik + '.segment_summary.md',
                    {
                        method: 'GET',
                        headers: headers //{'Authorization': accessToken},
                    }
                )
                    .then(
                        response => {
                            return response.text();
                            // setCompanySegmentSummary(response.text());
                        }
                    ).then(
                        data => {
                            setCompanySegmentSummary(data);
                        }
                    ).catch(
                        error => {
                            console.log(error);
                        }
                    )
            }
        }
    )

    return (
        <Card variant="outlined" sx={{ m: 1, pt: 1, pr: 1, pl: 1, minHeight: "68px" }}>
            <Grid container spacing={5}>
                <Grid item xs={expanded ? 7 : "auto"}>
                    <Stack direction="row" alignItems="center">
                        <Typography sx={{ lineHeight: '1.0', color: "var(--brand-main)" }} variant="h5">{!companyName ? <Skeleton sx={{ width: 200 }} /> : companyName}</Typography>
                        {<WatchlistButton ticker={ticker} shouldRender={!!companyName} />}
                    </Stack>
                    <Typography sx={{ height: "12px", m: 0 }} variant="h7">{exchangeName ? exchangeName + ': ' + ticker.toUpperCase() : <Skeleton sx={{ width: 150 }} />}</Typography>
                    {companyName && exchangeName && expanded &&
                        <Stack
                            sx={{
                                overflow: 'hidden',
                                pt: 2
                            }}
                        >
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1.0rem', color: "var(--brand-analogous)" }}>
                                Business Overview
                            </Typography>

                            <Stack direction="row" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <Typography sx={{
                                    pr: 0,
                                    pt: 1,
                                    flexGrow: 1
                                }}>
                                    {companySummary === null ? <Skeleton sx={{ width: '100%' }} /> : companySummary}
                                </Typography>
                            </Stack>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <Typography variant="body1">
                                    <ReactMarkdown
                                        children={comapnySegmentSummary?.replace(/\s\*\*/g, '**').replace(/\*\*\s*/g, '**').replace(/\+/g, '\+ ')}
                                    />

                                </Typography>
                            </Collapse>

                        </Stack>}
                </Grid>
                {companyName && exchangeName && !expanded && <Grid item md>
                    <Stack
                        sx={{
                            overflow: 'hidden'
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold', fontSize: '1.0rem', color: "var(--brand-analogous)" }}>
                            Business Overview and Comparable Companies
                        </Typography>
                        <Stack direction="row" style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{
                                pr: 4,
                                pt: 1,
                                flexGrow: 1,
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                                {companySummary === null ? <Skeleton sx={{ width: '100%' }} /> : companySummary}
                            </Typography>
                            {!expanded && (
                                <IconButton
                                    size='small'
                                    aria-label="expand/collapse"
                                    onClick={handleExpandClick}
                                    justifyContent="center"
                                >
                                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
                }
                {expanded &&
                    <Grid item xs={5}>
                        {companyName && exchangeName && ticker && <Comps ticker={ticker} />}
                    </Grid>
                }
            </Grid>
            {expanded && <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                    aria-label="expand/collapse"
                    onClick={handleExpandClick}
                    justifyContent="center"
                >
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Grid>}
        </Card>
    );

}