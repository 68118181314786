import React from 'react';
import { Card, Container, Grid, Box, Typography, List, ListItem, ListItemText, IconButton, Button, ListItemButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { makeGetRequest, makePostRequest } from 'revelata-common-ui';
import Skeleton from '@mui/material/Skeleton';
import { findCompanyAndFormatName } from 'revelata-common-ui'

const WatchedCompanies = () => {
    const [watchedCompanies, setWatchedCompanies] = useState();
    const allSuggestedCompanies = [
        { ticker: 'HP', cik: '46765', name: 'Helmerich & Payne, Inc.', exchange: 'NYSE' },
        { ticker: 'MTCH', cik: '891103', name: 'Match Group, Inc.', exchange: 'NASDAQ' },
        { ticker: 'EXPE', cik: '1324424', name: 'Expedia Group Inc.', exchange: 'NASDAQ' },
        { ticker: 'PLNT', cik: '1637207', name: 'Planet Fitness, Inc.', exchange: 'NYSE' }
    ];
    const [suggestedCompanies, setSuggestedCompanies] = useState(allSuggestedCompanies);
    const [accessToken, setAccessToken] = useState(null);

    const { route, user } = useAuthenticator((context) => [context.route, context.user]);

    useEffect(
        () => {
            Auth.currentSession()
                .then(resp => setAccessToken(resp.getIdToken().getJwtToken()))
                .catch(error => { console.log(error); })
        },
        []
    );

    useEffect(
        () => {
            if (user && user.username) {
                makeGetRequest(process.env.REACT_APP_API_BASE_URL + `/watchlist/${user.username}`, accessToken)
                    .then(data => {
                        const tickers = data.watchlist;
                        const companyInfoPromises = tickers.map(ticker => findCompanyAndFormatName(ticker));
                        Promise.all(companyInfoPromises)
                            .then(companies => {
                                setWatchedCompanies(companies);
                                const watchedTickers = companies.map(company => company.ticker);
                                setSuggestedCompanies(allSuggestedCompanies.filter(company => !watchedTickers.includes(company.ticker)));
                            })
                            .catch(error => console.log(error));
                    })
                    .catch(error => console.log(error));
            }
        },
        [user, accessToken]
    );

    const handleWatchClick = (ticker) => {
        if (user && user.username) {
            makePostRequest(`${process.env.REACT_APP_API_BASE_URL}/watchlist/${user.username}/${ticker}`, accessToken, {})
                .then(() => {
                    // Trigger re-render by fetching the updated watchlist
                    makeGetRequest(process.env.REACT_APP_API_BASE_URL + `/watchlist/${user.username}`, accessToken)
                        .then(data => {
                            const tickers = data.watchlist;
                            const companyInfoPromises = tickers.map(ticker => findCompanyAndFormatName(ticker));
                            Promise.all(companyInfoPromises)
                                .then(companies => {
                                    setWatchedCompanies(companies);
                                    const watchedTickers = companies.map(company => company.ticker);
                                    setSuggestedCompanies(allSuggestedCompanies.filter(company => !watchedTickers.includes(company.ticker)));
                                })
                                .catch(error => console.log(error));
                        })
                        .catch(error => console.log(error));
                })
                .catch(error => console.log(error));
        }
    };

    // console.log(user)

    return (
        <Container maxWidth="lg" sx={{}}>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={12} lg={12}>
                    <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle1" sx={{ pt: 0, m: 0, textAlign: "left", color: "var(--brand-main)", fontSize: "1.0rem", fontWeight: 'bold' }}>Watched Companies</Typography>
                        <Card variant="outlined" sx={{ mt: 1, minHeight: 200, display: 'flex', flexDirection: 'column' }}>
                            {watchedCompanies === undefined ? (
                                <Skeleton sx={{ flexGrow: 1, bgcolor: 'grey.900' }} variant="rectangular" />
                            ) : (
                                <>
                                    <List>
                                        {watchedCompanies.map((company, index) => (
                                            <ListItem disablePadding key={index}>
                                                <ListItemButton onClick={() => window.location.href = `?ticker=${company.ticker}&formType=10-K`}>
                                                    <ListItemText>
                                                        <Typography sx={{ fontSize: "0.75rem", fontWeight: "700", cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}>
                                                            {company.name} ({company.ticker})
                                                        </Typography>
                                                    </ListItemText>
                                                    <ArrowForwardIosIcon fontSize='small' />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Typography variant="h6" sx={{ maxHeight: "25px", pl: 2, textAlign: "left", color: "var(--mui-palette-grey-600)", fontSize: "0.875rem", fontWeight: 'bold' }}>Watch more companies:</Typography>
                                    <List>
                                        {suggestedCompanies.map((company, index) => (
                                            <ListItem disablePadding key={index} sx={{ pl: 2 }}>
                                                <Button startIcon={<VisibilityIcon />} sx={{ mr: 2, color: "var(--mui-palette-grey-600)", fontSize: "0.75rem", textTransform: "none" }} onClick={() => handleWatchClick(company.ticker)}>
                                                    Watch
                                                </Button>
                                                <ListItemText>
                                                    <Typography sx={{ fontSize: "0.75rem", fontWeight: "700", color: "var(--brand-analogous-50)", cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} onClick={() => window.location.href = `?ticker=${company.ticker}&formType=10-K`}>
                                                        {company.name} ({company.ticker})
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                </>
                            )}
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default WatchedCompanies;
