import { Box, Stack, Typography } from '@mui/material';
import RevelataLogoSvg from '../svg/revelata_logo.svg';

export function AuditPrompt() {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" sx={{p:4}}>
            <Stack spacing={3} sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <img src={RevelataLogoSvg} alt="Revelata Logo" style={{ width: "64px", height: "64px" }} />
                    <Typography sx={{color: "var(--brand-analogous-50)"}}>
                        Click on a datapoint in the chart or a cell in the table to audit its source filing.
                    </Typography>
            </Stack>
        </Box>
    );
}
