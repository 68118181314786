import { utils, writeFile } from 'xlsx-js-style';
import { encodeDatapoint, decodeDatapoint } from './DatapointProvenanceApi';
import { ampli } from 'revelata-common-ui';

// export function filterTimeSeries(ts, startDate, endDate) {
//     // let retval = [...ts['data']];    
//     // const fromDateTime = toDate(fromDate + "T00:00:00");

//     ts["series"] = ts["series"].map(
//          (dp) => {
//             const dpDate = new Date();
//             dpDate.setHours(0);
//             dpDate.setMinutes(0);
//             dpDate.setSeconds(0);            
//             dp['data']['shouldDisplay'] = (startDate <= dpDate <= endDate);

//             return dp;
//          }
//     )
//     return ts;
// }

// function columnwise_XlsxFormatOneTimeSeries(ts) {
//     const displayedDatapoints = ts['series'].filter(dp => dp['data']['canDisplay'] && dp['data']['isInDateRange'] && dp['data']['displayValue'] !== null);
//     //console.log(displayedDatapoints.length, ts['series'].length)
//     // console.log(ts);
//     const fts = {
//         'Description': ts['chartTitle'],
//         // 'CIK': ts['cik'],
//         'Form Type': ts['formType'],
//         'Document Type': ts['docType'].split('.')[0],
//         'Series': displayedDatapoints.map((dp) => {
//             return {
//                 'KPI Date': dp['data']['displayDatetime']+"T00:00:00",
//                 'KPI Value': parseFloat(dp['data']['displayValue']),
//                 'KPI Units': ts['unit'],
//                 // 'KPI Label': chartTitle,
//                 'SEC CIK': dp['cik'],
//                 'SEC Accession No.': dp['accNo'],
//                 'SEC Sequence No.': dp['seqNo'],
//                 'SEC Form Type': dp['formType'],
//                 'SEC Document Type': dp['docType'],
//                 'SEC Filing Date': dp['filingDate']+"T00:00:00",
//                 'SEC Reporting Period': dp['reportingPeriod']+"T00:00:00",
//                 'Audit URL': "Click to audit"
//             }
//         })
//     }

//     const pb = displayedDatapoints.map(
//         (dp) => {
//             const buf = encodeDatapoint(dp);            
//             // console.log(buf.length, buf)
//             // const decoded = decodeDatapoint(buf);
//             // console.log(decoded)
//             return buf;
//         }
//     )

//     return {fts: fts, pb: pb};
// }

function XlsxFormatOneTimeSeries(ts) {
    const displayedDatapoints = ts['series'].filter(dp => dp['data']['canDisplay'] && dp['data']['isInDateRange'] && dp['data']['displayValue'] !== null);
    //console.log(displayedDatapoints.length, ts['series'].length)
    // console.log(ts);
    const fts = {
        'Description': ts['chartTitle'],
        // 'CIK': ts['cik'],
        'Form Type': ts['formType'],
        'Document Type': ts['docType'].split('.')[0],
        'Series': 
            [
                ['', '', ...displayedDatapoints.map((dp) => ( dp['data']['displayDatetime']+"T00:00:00"))],
                [ts['chartTitle'], ts['unit'], ...displayedDatapoints.map((dp) => ( parseFloat(dp['data']['displayValue']) ))],
                // ['KPI Units', ...displayedDatapoints.map((dp) => ( ts['unit'] ))],
                // 'KPI Label': chartTitle,
                ['SEC CIK', '', ...displayedDatapoints.map((dp) => ( dp['cik'] ))],
                ['SEC Accession No.', '', ...displayedDatapoints.map((dp) => ( dp['accNo'] ))],
                ['SEC Sequence No.', '', ...displayedDatapoints.map((dp) => ( dp['seqNo'] ))],
                ['SEC Form Type', '', ...displayedDatapoints.map((dp) => ( dp['formType'] ))],
                ['SEC Document Type', '', ...displayedDatapoints.map((dp) => ( dp['docType'] ))],
                ['SEC Filing Date', '', ...displayedDatapoints.map((dp) => ( dp['filingDate']+"T00:00:00" ))],
                ['SEC Reporting Period', '', ...displayedDatapoints.map((dp) => ( dp['reportingPeriod']+"T00:00:00" ))],
                //['Audit URL', ...displayedDatapoints.map((dp) => ( "Click to audit" ))]
            ]
    }
    

    const pb = displayedDatapoints.map(
        (dp) => {
            const buf = encodeDatapoint(dp);            
            // console.log(buf.length, buf)
            // const decoded = decodeDatapoint(buf);
            // console.log(decoded)
            return buf;
        }
    )

    return {fts: fts, pb: pb};
}


// const HEADER_ORDER = [
//     'KPI Date', 
//     'KPI Value', 
//     'KPI Units', 
//     'Audit URL', 
//     'SEC CIK', 
//     'SEC Accession No.', 
//     'SEC Sequence No.', 
//     'SEC Form Type', 
//     'SEC Document Type', 
//     'SEC Filing Date', 
//     'SEC Reporting Period'
// ];


function genSheetNames(timeSeriesList) {    
    const semicolonSplits = timeSeriesList.map(
        (ts) => {
            return ts['description'].split(';')
        }
    )

    let descs = semicolonSplits.map(
        (l) => {
            // Excel sheet name max len = 31.  We split 20/10, between description and modifiers, if
            // there are modifiers. 
            let descMaxLen = l.length > 1 ? 20 : 31;
            let modMaxLen = l.length > 1 ? 31 : 0;
            
            const words = l[0].split(" ");

            // Capitalize the words
            const capWords = words.map(
                (word) => {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                }
            )

            // Join capitalized description words up to descMaxLen
            let retval = "";
            for (let i = 0; i < capWords.length; i++) {
                if (retval.length + capWords[i].length > descMaxLen) {
                    break
                }
                retval += capWords[i]
            }

            // Join modifier words up to modMaxLen
            if (l.length > 1) {
                retval += ".";
                const modwords = l[1].split(" ");
                const modCapWords = modwords.map(
                    (word) => {
                        return word.charAt(0).toUpperCase() + word.slice(1);
                    }
                )
                for (let j = 0; j < modCapWords.length; j++) {
                    if (retval.length > descMaxLen + 1 + modMaxLen) {
                        break
                    }
                    retval += modCapWords[j]
                }
            }
            return retval;
        }
    )

    // Count of distinct base sheetname occurrences
    let maxCounts = descs.reduce(
        (m, k) => { m[k] = m[k] + 1 || 1; return m }, {}
    );

    // Need this counter for distinct base sheet name occurrences.  This determines the sheet ID
    // number appended to the sheetname to make otherwise equal sheetnames unique.
    // e.g., ["SheetName", "SheetName"] becomes ["SheetName1", "SheetName2"]
    let counts = {};
    for (const key in maxCounts) {
        counts[key] = 0;
    }

    for (let i = 0; i < descs.length; i++) {
        let key = descs[i];
        if (maxCounts[key] > 0) {
            const id = counts[key].toString();
            if (descs[i].length + id.length > 31) {
                descs[i] = descs[i].slice(0, 31 - id.length) + id;
            } else {
                descs[i] = descs[i] + id;
            }            
            counts[key] += 1;
        }        
    }

    return descs;
}

// export function columnwise_exportXlsxWorkbook(filename, ticker, stockExchange, companyName, fromDate, toDate, timeSeriesList) {
//     const sheetNames = genSheetNames(timeSeriesList);
//     // console.log(sheetNames);

//     const workbook = utils.book_new();
//     for (let i = 0; i < timeSeriesList.length; i++) {
//         let {fts, pb} = XlsxFormatOneTimeSeries(timeSeriesList[i]);
//         //console.log(fts);
        
//         let worksheetName = sheetNames[i];            

//         let frontMatter = [
//             [{ 
//                 v: companyName + " (" + stockExchange + ": " + ticker.toUpperCase() + ")",  //+ " (CIK: " + fts['CIK'] + ")",
//                 t: "s", 
//                 s: { font: { name: "Arial", bold: true, sz: 16} }
//             }],
//             [
//                 {
//                     v: fts['Description'], 
//                     t: "s", 
//                     s: { font: { name: "Arial", bold: true, sz: 16} }
//                 }
//             ],
//             [{
//                 v: "Date range: " + fromDate.toISOString().split('T')[0] + " to " + toDate.toISOString().split('T')[0], 
//                 t: "s",                 
//                 s: { font: { name: "Arial", sz: 11} }
//             }],
//             [{
//                 v: "Extracted from SEC Form " + fts['Form Type'] + " (" + fts['Document Type'] +")", 
//                 t: "s",
//                 s: { font: { name: "Arial", sz: 11} }
//             }],
//             [{
//                 v: "Exported on " + new Date().toISOString().split('T')[0],
//                 t: "s",
//                 s: { font: { name: "Arial", sz: 11} }
//             }],
//             [{
//                 v: "Author: deepKPI by Revelata, Inc.",
//                 t: "s",
//                 s: { font: { name: "Arial", sz: 11} }
//             }], 
//         ]
//         const FRONT_MATTER_LEN = frontMatter.length + 3; // We skip 3 rows so that the first data row starts at A10, which is easy to remember when used as a driver sheet.
//         let sheet = utils.aoa_to_sheet(frontMatter)
//         utils.sheet_add_json(sheet, fts['Series'], { origin: "A9", header: HEADER_ORDER})

//         // Set all the data cells to Arial
//         const range = utils.decode_range(sheet['!ref']);
//         for (let row = range.s.r + FRONT_MATTER_LEN - 1; row <= range.e.r; row++) {
//             for (let col = range.s.c; col <= range.e.c; col++) {
//                 let cell = utils.encode_cell({r: row, c: col});                
//                 if (row === range.s.r + FRONT_MATTER_LEN - 1) { // The header row
//                     sheet[cell].s = { font: {name: "Arial", bold: true, sz: 11 }, alignment: { horizontal: "right"} };
//                 } else {
//                     if ([0, 9, 10].includes(col)) {         
//                         sheet[cell].t = 'd'
//                         sheet[cell].s = { font: {name: "Arial", sz: 11 }, alignment: { horizontal: "right"}};                                                
//                         sheet[cell].z = "yyyy-mm-dd";
//                         // console.log(sheet[cell]);
//                     } else if (col === 1) { 
//                         sheet[cell].s = { font: {name: "Arial", sz: 11 }, alignment: { horizontal: "right"}, numFmt: "#,##0.00"};
//                     }                    
//                     else {
//                         sheet[cell].s = { font: {name: "Arial", sz: 11 }, alignment: { horizontal: "right"} };
//                     }
//                 }
//             }
//         }

//         // console.log(utils.sheet_to_json(sheet));

//         // Set up the audit links 
//         for (let row = range.s.r+FRONT_MATTER_LEN; row <= range.e.r; row++) {
//             let datapoint_idx = row - FRONT_MATTER_LEN
//             let cell = utils.encode_cell({r: row, c: HEADER_ORDER.indexOf("Audit URL")});

//             sheet[cell].l = { "Target": process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PROVENANCE_ENDPOINT + "?d=" + pb[datapoint_idx]};
//             sheet[cell].s = { font: { name: "Arial", underline: true, color: { rgb: "FF0000FF" } }, alignment: { horizontal: "right"} } ;            
//         }

//         // Set the col padding so that the sheet looks visually pleasing.
//         const data = utils.sheet_to_json(sheet, { origin: "A9", header: HEADER_ORDER } ).slice(6);        
//         const colLengths = Object.keys(data[0]).map((k) => k.toString().length)
//         for (const d of data) {
//             Object.values(d).forEach((element, index) => {
//                 const length = element.toString().length
//                 if (colLengths[index] < length) {
//                     colLengths[index] = length
//                 }
//             })
//         }
//         colLengths[1] += 8; // This is the KPI value column, which needs extra padding.
//         sheet["!cols"] = colLengths.map((l) => {            
//             return {
//                 wch: l + 2,
//             }
//         })   
        
//         utils.book_append_sheet(
//             workbook, 
//             sheet,
//             worksheetName.substring(0, 31)
//         )

//         ampli.dataExport(
//             {
//                 "Export Details": [
//                     ticker,
//                     fts['Form Type'],
//                     fts['Document Type'],
//                     fts['Description'],       
//                     fromDate.toISOString().split('T')[0],
//                     toDate.toISOString().split('T')[0]                                 
//                 ]
//             }
//         );
//     }

//     writeFile(
//         workbook, 
//         filename, 
//         {
//             bookType: "xlsx",
//             type: "string",             
//         }
//     );
// }

// Per Eric, we should be exporting row-wise time series, not column-wise.
export function exportXlsxWorkbook(filename, ticker, stockExchange, companyName, fromDate, toDate, timeSeriesList) {
    // const sheetNames = genSheetNames(timeSeriesList);
    // console.log(sheetNames);

    let worksheetName = "deepKPI Time Series";

    const workbook = utils.book_new();

    let {fts, pb} = XlsxFormatOneTimeSeries(timeSeriesList[0]);

    let frontMatter = [
        [{ 
            v: companyName + " (" + stockExchange + ": " + ticker.toUpperCase() + ")",  //+ " (CIK: " + fts['CIK'] + ")",
            t: "s", 
            s: { font: { name: "Arial", bold: true, sz: 16} }
        }],
        // [
        //     {
        //         v: fts['Description'], 
        //         t: "s", 
        //         s: { font: { name: "Arial", bold: true, sz: 16} }
        //     }
        // ],
        [{
            v: "Date range: " + fromDate.toISOString().split('T')[0] + " to " + toDate.toISOString().split('T')[0], 
            t: "s",                 
            s: { font: { name: "Arial", sz: 11} }
        }],
        [{
            v: "Extracted from SEC Form " + fts['Form Type'] + " (" + fts['Document Type'] +")", 
            t: "s",
            s: { font: { name: "Arial", sz: 11} }
        }],
        [{
            v: "Exported on " + new Date().toISOString().split('T')[0],
            t: "s",
            s: { font: { name: "Arial", sz: 11} }
        }],
        [{
            v: "Source: deepKPI by Revelata, Inc.",
            t: "s",
            s: { font: { name: "Arial", sz: 11 } }
        }], 
        [{
            v: process.env.REACT_APP_DEEPKPI_URL,
            t: "s",
            s: { font: { name: "Arial", sz: 11, underline: true, color: {rgb: "FF0000FF" } } }, 
            l: { "Target": process.env.REACT_APP_DEEPKPI_URL }
        }], 
    ]
    let sheet = utils.aoa_to_sheet(frontMatter)
    
    // We skip 2 rows so that the first data row starts at A10, which is easy to remember when used as a driver sheet.
    let totalRowCount = frontMatter.length + 2; 
    
    sheet["!rows"] = Array(totalRowCount).fill().map(() => ({}));
    //console.log(totalRowCount, sheet["!rows"]);

    for (let i = 0; i < timeSeriesList.length; i++) {
        let {fts, pb} = XlsxFormatOneTimeSeries(timeSeriesList[i]);
        //console.log(fts);
        
        utils.sheet_add_aoa(sheet, fts['Series'], { origin: totalRowCount })
        //console.log(sheet);
        
        // Set all the data cells to Arial
        const range = utils.decode_range(sheet['!ref']);  // s = start cell (upper left), e = end cell (lower right)                
        let groupStart = range.s.r + totalRowCount;
        let groupEnd = range.e.r;
        //console.log("groupStart", groupStart);
        for (let row = groupStart; row <= groupEnd; row++) {                        
            for (let col = 0; col < fts['Series'][0].length; col++) {
                let cell = utils.encode_cell({r: row, c: col});                
                
                if (col === 0) { // first column are the headers
                    sheet[cell].s = { font: {name: "Arial", bold: true, sz: 11 }, alignment: { horizontal: "left"} };
                    if (row === groupStart + 0) {
                        sheet[cell].s = {
                            fill: {
                                type: 'pattern',
                                patternType: 'solid',
                                fgColor: { rgb: "e8f0f8" }
                            }
                        }
                    }
                }
                else if (col === 1) {
                    if (row === groupStart + 0) {
                        sheet[cell].s = {
                            fill: {
                                type: 'pattern',
                                patternType: 'solid',
                                fgColor: { rgb: "e8f0f8" }
                            }
                        }
                    }
                } else {                              
                    if ([groupStart + 0, groupStart + 7, groupStart + 8].includes(row) && col > 1) { // these are rows that need date formatting  @@@ckl need to make these depend on i 
                        //console.log(cell, row, col, sheet[cell]);
                            sheet[cell].t = 'd'
                            sheet[cell].s = { font: {name: "Arial", bold: true, sz: 11 }, alignment: { horizontal: "right"} };
                            if (row === groupStart + 0) {
                                sheet[cell].s.fill = {
                                    type: 'pattern',
                                    patternType: 'solid',
                                    fgColor: { rgb: "e8f0f8" }
                                }
                            }
                            sheet[cell].z = "yyyy-mm-dd";
                            // console.log(sheet[cell]);                        
                    } 
                    else if (row === groupStart + 1 && col > 1) { // the KPI row needs number formatting
                        sheet[cell].s = { 
                            font: {name: "Arial", sz: 11, underline: true, color: { rgb: "FF0000FF" } },
                            alignment: { horizontal: "right"}, 
                            numFmt: "#,##0.00"};
                        sheet[cell].l = { 
                            "Target": process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PROVENANCE_ENDPOINT + "?d=" + pb[col - 2], 
                            "Tooltip": "Click to audit.  Click and hold to select."
                        };
                    }                    
                    else { // the rest of the rows                        
                        sheet[cell].s = { font: {name: "Arial", sz: 11 }, alignment: { horizontal: "right"} };                        
                    }  
                }              
            }
        }

        // Create row grouping
        sheet["!rows"] = sheet["!rows"].concat(
            Array(2).fill({level: 1}),
            Array(fts['Series'].length - 2).fill({level: 2, hidden: true}), 
            [{level: 1}, {}]
        );

        totalRowCount += fts['Series'].length + 2;
        // console.log("totalRowCount after", totalRowCount);

        // // Set up the audit links 
        // for (let col = range.s.c+1; col <= range.e.c; col++) {
        //     let cell = utils.encode_cell({r: range.e.r, c: col});            
        //     sheet[cell].l = { "Target": process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PROVENANCE_ENDPOINT + "?d=" + pb[col - 1]};
        //     sheet[cell].s = { font: { name: "Arial", underline: true, color: { rgb: "FF0000FF" } }, alignment: { horizontal: "right"} } ;            
        // }


        ampli.dataExport(
            {
                "Export Details": [
                    ticker,
                    fts['Form Type'],
                    fts['Document Type'],
                    fts['Description'],       
                    fromDate.toISOString().split('T')[0],
                    toDate.toISOString().split('T')[0]                                 
                ]
            }
        );
        
    }

    // Set the col padding so that the sheet looks visually pleasing.
    const data = utils.sheet_to_json(sheet, { header: 1, range: 9, raw: false } );    
    const longestRowIdx = data.reduce(
        (a, arr, idx) => arr.length > data[a].length ? idx : a, 
        0
    );
    const colLengths = Object.keys(data[longestRowIdx]).map((k) => k.toString().length)
    for (const d of data) {
        Object.values(d).forEach((element, index) => {
            const length = element.toString().length
            if (colLengths[index] < length) {
                colLengths[index] = length
            }
        })
    }
    
    sheet["!cols"] = colLengths.map((l) => {            
        return {
            wch: l + 2,
        }
    })   
    
    utils.book_append_sheet(
        workbook, 
        sheet,
        worksheetName.substring(0, 31)
    )

    writeFile(
        workbook, 
        filename, 
        {
            bookType: "xlsx",
            type: "string",             
        }
    );
}

function XlsxFormatOneTableSeries(ts) {
    const displayedDatapoints = ts['tableSeries'];
    //console.log(displayedDatapoints.length, ts['series'].length)
    function create1DArrayFrom2D(arr2D) {
        const result = [];
      
        // Assuming both arrays have the same number of columns
        const numCols = arr2D[0].cell.length;
      
        for (let col = 0; col < numCols; col++) {
          result[col] = "";
          for (let row = 0; row < arr2D.length; row++) {
            if (arr2D[row].cell[col].provenance && arr2D[row].cell[col].provenance.accNo) {
              result[col] = arr2D[row].cell[col].provenance.accNo;
              break; // Found the first non-empty string, move to next column
            }
          }
        }
      
        return result;
      }
      function createFilingDateArray(arr2D) {
        const result = [];
      
        // Assuming both arrays have the same number of columns
        const numCols = arr2D[0].cell.length;
      
        for (let col = 0; col < numCols; col++) {
          result[col] = "";
          for (let row = 0; row < arr2D.length; row++) {
            if (arr2D[row].cell[col].provenance && arr2D[row].cell[col].provenance.filingDate) {
              result[col] = arr2D[row].cell[col].provenance.filingDate;
              break; // Found the first non-empty string, move to next column
            }
          }
        }
      
        return result;
      }

    function findLatestReportingDates(eventDateArray, reportingDateArray) {
        // Create a map to store eventDate and its most recent reportingDate
        const eventDateMap = new Map();
      
        // Iterate through both arrays simultaneously
        for (let i = 0; i < eventDateArray.length; i++) {
          const eventDate = eventDateArray[i];
          const reportingDate = reportingDateArray[i];
        //   console.log(reportingDate, new Date(reportingDate))
      
          // If eventDate exists in the map, compare reporting dates
          if (eventDateMap.has(eventDate)) {
            const existingReportingDate = eventDateMap.get(eventDate);
            eventDateMap.set(eventDate, new Date(reportingDate) > new Date(existingReportingDate) ? reportingDate : existingReportingDate);
          } else {
            // If eventDate doesn't exist, add it to the map
            eventDateMap.set(eventDate, reportingDate);
          }
        }
      
        // Create the result array
        const resultArray = [];
        for (let i = 0; i < eventDateArray.length; i++) {
          const eventDate = eventDateArray[i];
          const latestReportingDate = eventDateMap.get(eventDate);
          resultArray.push(reportingDateArray[i] === latestReportingDate ? 1 : 0);
        }
      
        return resultArray;
      }

    let d =  findLatestReportingDates(ts["timeContextHeaders"][1].date, createFilingDateArray(displayedDatapoints))
    // console.log("MASK", d)
    const fts = {
        'Description': ts['chartTitle'],
        // 'CIK': ts['cik'],
        'Form Type': ts['formType'],
        'Document Type': ts['docType'].split('.')[0],
        'Series': 
            [
                
                [
                    "",  // First Column Shouldn't have any time context.
                    ...ts["timeContextHeaders"][0].date.slice(1),
                  ].map((t) => (t ? t + "T00:00:00" : t)),
                  [
                    "",  // First Column Shouldn't have any time context.
                    ...ts["timeContextHeaders"][1].date.slice(1),
                  ],
                displayedDatapoints.map((r) => (r.cell.map(item => item.value))),
                d,
                // ['KPI Units', ...displayedDatapoints.map((dp) => ( ts['unit'] ))],
                // 'KPI Label': chartTitle,
                // ['SEC CIK', '', ...displayedDatapoints.map((dp) => ( dp['cik'] ))],
                // ['SEC Accession No.', '', ...displayedDatapoints.map((dp) => ( dp['accNo'] ))],
                // ['SEC Sequence No.', '', ...displayedDatapoints.map((dp) => ( dp['seqNo'] ))],
                // ['SEC Form Type', '', ...displayedDatapoints.map((dp) => ( dp['formType'] ))],
                // ['SEC Document Type', '', ...displayedDatapoints.map((dp) => ( dp['docType'] ))],
                // ['SEC Filing Date', '', ...displayedDatapoints.map((dp) => ( dp['filingDate']+"T00:00:00" ))],
                // ['SEC Reporting Period', '', ...displayedDatapoints.map((dp) => ( dp['reportingPeriod']+"T00:00:00" ))],
                //['Audit URL', ...displayedDatapoints.map((dp) => ( "Click to audit" ))]
            ]
    }
    
    const pb = displayedDatapoints.map((r) => (r.cell.map(item => {

        const data = 
            {
                "xpathNodes": item.provenance ? item.provenance.xpathNodes : [],
                // "cik": ,
                "accNo": item.provenance ?  item.provenance.accNo  : "",
                "seqNo": item.provenance ?  item.provenance.seqNo : "",
                // "fyEnd": "",
                "formType": item.provenance ?  item.provenance.formType : "",
                "docType": item.provenance ?  item.provenance.docType : "",
                // "docDesc": "10-K",
                "filingDate": item.provenance ?  item.provenance.filingDate : "",
                "reportingPeriod": item.provenance ?  item.provenance.reportingPeriod : "",
                "sentence": item.provenance ?  item.value : "",
                "data": {
                    "canDisplay": true,
                    "value": item.provenance ?  item.value : "",
                    // "displayHint": "",
                    // "displayDatetime": "2017-12-31",
                    "displayValue": item.provenance ?  item.value : "",
                    "isInDateRange": true
                },
                // "url": "http://www.sec.gov/Archives/edgar/data/1564408/000156459018002721/snap-10k_20171231.htm"
            }       
        const buf = encodeDatapoint(data);     
        return buf;
    })))
    // const pb = displayedDatapoints.map(
    //     (dp) => {
    //         console.log(dp)
    //         const buf = encodeDatapoint(dp);            
    //         // console.log(buf.length, buf)
    //         // const decoded = decodeDatapoint(buf);
    //         // console.log(decoded)
    //         return buf;
    //     }
    // )

    return {fts: fts, pb: pb};
}

function findLastYearlyRowIndex(array) {
    // We ignore the first column!
    for (let i = Math.min(array.length - 1, 3); i >= 0; i--) {
        // console.log(array[i])
    
      if (array[i].slice(1).every(element => /\d{4}/.test(element) || element === '') && array[i].slice(1).some(element => element !== '')) {
        return i;
      }
    }
    return -1; // Not found
  }


// Per Eric, we should be exporting row-wise time series, not column-wise.
export function exportTableXlsxWorkbook(filename, ticker, stockExchange, companyName, fromDate, toDate, timeSeriesList) {
    // const sheetNames = genSheetNames(timeSeriesList);
    // console.log(sheetNames);

    let worksheetName = "deepKPI Time Series";

    const workbook = utils.book_new();

    let {fts, pb} = XlsxFormatOneTableSeries(timeSeriesList[0]);

    let frontMatter = [
        [{ 
            v: companyName + " (" + stockExchange + ": " + ticker.toUpperCase() + ")",  //+ " (CIK: " + fts['CIK'] + ")",
            t: "s", 
            s: { font: { name: "Arial", bold: true, sz: 16} }
        }],
        // [
        //     {
        //         v: fts['Description'], 
        //         t: "s", 
        //         s: { font: { name: "Arial", bold: true, sz: 16} }
        //     }
        // ],
        [{
            v: "Date range: " + fromDate.toISOString().split('T')[0] + " to " + toDate.toISOString().split('T')[0], 
            t: "s",                 
            s: { font: { name: "Arial", sz: 11} }
        }],
        [{
            v: "Extracted from SEC Form " + fts['Form Type'] + " (" + fts['Document Type'] +")", 
            t: "s",
            s: { font: { name: "Arial", sz: 11} }
        }],
        [{
            v: "Exported on " + new Date().toISOString().split('T')[0],
            t: "s",
            s: { font: { name: "Arial", sz: 11} }
        }],
        [{
            v: "Source: deepKPI by Revelata, Inc.",
            t: "s",
            s: { font: { name: "Arial", sz: 11 } }
        }], 
        [{
            v: process.env.REACT_APP_DEEPKPI_URL,
            t: "s",
            s: { font: { name: "Arial", sz: 11, underline: true, color: {rgb: "FF0000FF" } } }, 
            l: { "Target": process.env.REACT_APP_DEEPKPI_URL }
        }], 
    ]
    let sheet = utils.aoa_to_sheet(frontMatter)
    
    // We skip 2 rows so that the first data row starts at A10, which is easy to remember when used as a driver sheet.
    let totalRowCount = frontMatter.length + 2; 
    
    sheet["!rows"] = Array(totalRowCount).fill().map(() => ({}));
    //console.log(totalRowCount, sheet["!rows"]);

    let emptyRowColor = "d9d9d9";
    let firstColColor = "ddebf7";

    for (let i = 0; i < timeSeriesList.length; i++) {
        let {fts, pb} = XlsxFormatOneTableSeries(timeSeriesList[i]);
        let groupStartOffset = totalRowCount;
        // console.log(timeSeriesList[i].description)

        utils.sheet_add_aoa(sheet, [[timeSeriesList[i].description]], { origin: totalRowCount })
        totalRowCount = totalRowCount + 1;
        utils.sheet_add_aoa(sheet, [fts['Series'][0]], { origin: totalRowCount })
        totalRowCount = totalRowCount + 1;
        utils.sheet_add_aoa(sheet, [fts['Series'][1]], { origin: totalRowCount })
        for (let i = 0; i < fts['Series'][2].length; i++) {
            totalRowCount = totalRowCount + 1;
            utils.sheet_add_aoa(sheet, [fts['Series'][2][i]], { origin: totalRowCount });
          }
        // console.log("SHEET", sheet);
        
        // Set all the data cells to Arial
        const range = utils.decode_range(sheet['!ref']);  // s = start cell (upper left), e = end cell (lower right)                
        let groupStart = range.s.r + groupStartOffset;
        let groupEnd = range.e.r;
        //console.log("groupStart", groupStart);
        let timeContextRowIndex = findLastYearlyRowIndex(fts['Series'][2])
        // console.log("T ENDS", timeContextRowIndex)

        for (let row = groupStart; row <= groupEnd; row++) {                        
            for (let col = 0; col < fts['Series'][0].length; col++) {
                let cell = utils.encode_cell({r: row, c: col}); 
                if (sheet[cell] === undefined){
                    continue;
                }
                if (col === 0) { // first column are the headers
                    sheet[cell].s = { font: {name: "Arial", bold: true, sz: 11 }, alignment: { horizontal: "left"} };
                    // console.log(cell, sheet[cell])
                    // console.log(row, groupStart)
                    if (row === groupStart + 0 || row === groupStart + 1 || row === groupStart + 2) {
                        let color = (row === groupStart + 0) ? "0b5394" : "2f75b5"
                        sheet[cell].s = {
                            fill: {
                                type: 'pattern',
                                patternType: 'solid',
                                fgColor: { rgb: color }
                            },
                            font: {name: "Arial", bold: true, sz: 11, color: { rgb: "ffffff" } }
                        }
                    } else if (fts['Series'][2][row-3-groupStart].slice(1).every((cell) => cell === "")) {
                        sheet[cell].s.fill = {
                            type: 'pattern',
                            patternType: 'solid',
                            fgColor: { rgb: emptyRowColor }
                        }
                    } else {
                        sheet[cell].s.fill = {
                            type: 'pattern',
                            patternType: 'solid',
                            fgColor: { rgb: firstColColor }
                        }
                    }
                }
                else {
                    // console.log("CHECK")
                    // console.log(groupStart)
                    // console.log([groupStart + 1, groupStart + 7, groupStart + 8].includes(row)) 
                    // console.log(col)    
                    // console.log(sheet[cell])                         
                    if ([groupStart + 1, groupStart + 2].includes(row) && col >= 1) { // these are rows that need date formatting  @@@ckl need to make these depend on i 
                            
                            sheet[cell].s = { font: {name: "Arial", bold: true, sz: 11, color: { rgb: "ffffff" } }, alignment: { horizontal: "right"} };
                            if (row === groupStart + 1 || row === groupStart + 2) {
                                sheet[cell].s.fill = {
                                    type: 'pattern',
                                    patternType: 'solid',
                                    fgColor: { rgb: "2f75b5" }
                                }
                            }
                            if (row === groupStart + 1) {
                                sheet[cell].t = 'd'
                                sheet[cell].z = "yyyy-mm-dd";
                            }
                            
                            
                            
                            
                            /* Add comment part to the comment array */
                            // console.log(fts['Series'][2], fts['Series'][2][col])
                            // if (fts['Series'][2][col] !== 0) {
                            //     sheet[cell].c = [];
                            //     /* create a comment part */
                            //     var comment_part = {
                            //         a: 'deepKPI by Revelata, Inc.',
                            //         t: 'Most Recent Data Available'
                            //     };
                            //     sheet[cell].c.push(comment_part);
                            // }
                            
                            // console.log(sheet[cell])                       
                    } 
                    else if ((groupStart + 1 <= row <= groupStart + fts['Series'][2].length) && col >= 0) { // the KPI row needs number formatting
                        // console.log("CHECK")
                        // console.log(col)
                        // console.log(fts['Series'][2])   
                        // console.log(fts['Series'][2][col]) 
                        // console.log(sheet[cell]);
                        const uniqueElements = [...new Set(fts['Series'][2].slice(1))];
                        const index = uniqueElements.indexOf(fts['Series'][2][col]) % 2;
                        // console.log(index)
                        sheet[cell].s = { 
                            font: {name: "Arial", sz: 11, underline: true, color: { rgb: "FF0000FF" } },
                            alignment: { horizontal: "right"}, 
                            numFmt: "#,##0.00"};
                        sheet[cell].l = { 
                            "Target": process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PROVENANCE_ENDPOINT + "?d=" + pb[row-3-groupStart][col], 
                            "Tooltip": "Click to audit.  Click and hold to select."
                        };
                        
                        if (timeContextRowIndex >= row-3-groupStart) {
                            sheet[cell].s.font.color = { rgb: "000000" }
                        }

                        
                        if (fts['Series'][2][row-3-groupStart].slice(1).every((cell) => cell === "")) {
                            sheet[cell].s.fill = {
                                type: 'pattern',
                                patternType: 'solid',
                                fgColor: { rgb: emptyRowColor }
                            }
                        } else {
                            
                            let fgColor = fts['Series'][3][col] !== 0 ? "e8f0f8" :  "ffffff" 
                            // console.log("MASK", fts['Series'][3][col], "COLOR", fgColor)
                            // console.log(fts['Series'][3])
                            sheet[cell].s.fill = {
                                type: 'pattern',
                                patternType: 'solid',
                                fgColor: { rgb: fgColor }
                            }
                        }
                        
                    }                    
                    else { // the rest of the rows                   
                        sheet[cell].s = { font: {name: "Arial", sz: 11 }, alignment: { horizontal: "right"} };                        
                    }  
                }              
            }
        }

        // Create row grouping
        // sheet["!rows"] = sheet["!rows"].concat(
        //     Array(2).fill({level: 1}),
        //     Array(fts['Series'].length - 2).fill({level: 2, hidden: true}), 
        //     [{level: 1}, {}]
        // );

        totalRowCount += fts['Series'].length + 2;
        // console.log("totalRowCount after", totalRowCount);

        // // Set up the audit links 
        // for (let col = range.s.c+1; col <= range.e.c; col++) {
        //     let cell = utils.encode_cell({r: range.e.r, c: col});            
        //     sheet[cell].l = { "Target": process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PROVENANCE_ENDPOINT + "?d=" + pb[col - 1]};
        //     sheet[cell].s = { font: { name: "Arial", underline: true, color: { rgb: "FF0000FF" } }, alignment: { horizontal: "right"} } ;            
        // }


        // ampli.dataExport(
        //     {
        //         "Export Details": [
        //             ticker,
        //             fts['Form Type'],
        //             fts['Document Type'],
        //             fts['Description'],       
        //             fromDate.toISOString().split('T')[0],
        //             toDate.toISOString().split('T')[0]                                 
        //         ]
        //     }
        // );
        
    }

    // Set the col padding so that the sheet looks visually pleasing.
    const data = utils.sheet_to_json(sheet, { header: 1, raw: false } );    
    const longestRowIdx = data.reduce(
        (a, arr, idx) => arr.length > data[a].length ? idx : a, 
        0
    );
    const colLengths = Object.keys(data[longestRowIdx]).map((k) => k.toString().length)
    for (const d of data) {
        Object.values(d).forEach((element, index) => {
            const length = element.toString().length
            if (colLengths[index] < length) {
                colLengths[index] = length
            }
        })
    }

    
    sheet["!cols"] = colLengths.map((l, idx) => {    
        if (idx === 0) {
            return {
            wch: 60,
        }
        }        
        return {
            wch: 15,
        }
    })   
    
    utils.book_append_sheet(
        workbook, 
        sheet,
        worksheetName.substring(0, 31)
    )

    writeFile(
        workbook, 
        filename, 
        {
            bookType: "xlsx",
            type: "string",             
        }
    );
}