import React from 'react';
import RecentSearch from './RecentSearch';
import WatchedCompanies from './Watchlist';
import {Grid } from '@mui/material';

const Dashboard = () => {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={4} xl={3}>
                <RecentSearch />
            </Grid>
            <Grid item xs={6} xl={4}>
                <WatchedCompanies />
            </Grid>
        </Grid>
    );
}

export default Dashboard;
