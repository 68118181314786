import './../../App.css';
import { useContext, useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Box, Button, Container, createTheme, ThemeProvider} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SearchIcon from '@mui/icons-material/Search';
// import enUs from 'date-fns/locale/en-US';
// import { LocalizationProvider } from '@mui/x-date-pickers';
import { 
    // DatePickerElement, 
    FormContainer, SelectElement, AutocompleteElement } from 'react-hook-form-mui';

import { SUPPORTED_FORM_TYPES } from './constants';
import { SearchBarContext, OnboardingWizardContext } from './Contexts';
import { OnboardingWizard, advanceWizardState } from './Onboarding';

// import options from './sp1500.json';
import { ampli, SP1500 as options } from 'revelata-common-ui';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { makePostRequest } from 'revelata-common-ui';


export function SearchBar() {
    const [searchParams, setSearchParams] = useSearchParams();        
    const [defaultTicker, defaultFormType] = useContext(SearchBarContext);    
    const [wizardState, setWizardState] = useContext(OnboardingWizardContext);
    
    const [noTickerSelected, setNoTickerSelected] = useState(defaultTicker === undefined || defaultTicker === null);

    const [accessToken, setAccessToken] = useState(null);

    const { user } = useAuthenticator((context) => [context.user]);

    useEffect(() => {
        Auth.currentSession()
            .then(resp => setAccessToken(resp.getIdToken().getJwtToken()))
            .catch(error => { console.log(error); });
    }, []);

    const handleSubmit = (data) => {   
        // We don't send the date range here any more, but our Amplitude definition requires the 
        // date range, so we just send the empty string.
        ampli.query({ "Query Date Range": ['', ''], "Query Form Type": data['formType'], "Query Symbol": data['companySearch']['ticker']});
        if (user && user.username) {
            makePostRequest(`${process.env.REACT_APP_API_BASE_URL}/search_history/${user.username}/${data['companySearch']['ticker']}/deepKPI`, accessToken, {})
        }
        setSearchParams({
            'ticker': data['companySearch']['ticker'], 
            'formType': data['formType'], 
        });
    }

    // const renderAutocompleteOption = (props, option, state, ownerState) => {                
    //     console.log(state)
    //     console.log(option);        
    //     return option.label                    
    // }

    const excelAddInButtonTheme = createTheme(
        {
            components: {
                MuiButton: {
                    styleOverrides: {
                        contained: {
                            backgroundColor: "#60B69C",
                            "&:hover": {
                                backgroundColor: "#3AE2AF",
                            },
                        }
                    }
                }
            }
        }
    );


    const searchBarComponentName = ['companySearch', 'formType', 'searchButton'];
    
    return (
        <Box sx={{ pt: "10px", borderTop: 1, borderColor: 'grey.800'}}>        
        <Container maxWidth={false}>                    
            <FormContainer 
                defaultValues={{
                    'companySearch': options.find(option => option.ticker === defaultTicker),  // cannot use searchBarComponentName[0] as key; linter complains
                    'formType': defaultFormType, 
                }}
                onSuccess={handleSubmit}                                                
            >
                <Grid container spacing={1} minWidth="400px" > 
                    <Grid xs={4}></Grid>
                    <Grid xs={3}>                    
                        <OnboardingWizard step={0} placement="left-start" width="300px"><div>
                            <AutocompleteElement
                                    options={options}
                                    name={searchBarComponentName[0]}
                                    label={'Search the S&P 1500 (beta limit)'}                                    
                                    size="small"     
                                    // required                                                              
                                    //rules={{required: true}}  // This is to prevent the "This is required" from showing and pushing everything down 
                                    textFieldProps={{InputProps: {endAdornment: null}, autoFocus: true,                                    
                                    onInput: (event) => {
                                        // console.log("onInput", event, event.target.value !== "");
                                        if (event.target.value !== "") {
                                            setNoTickerSelected(false);
                                        } else {
                                            setNoTickerSelected(true);
                                        }
                                    },
                                    onBlur: (event) => {
                                        // const t = {...event.target};
                                        // console.log("onBlur", t, t.value);
                                        // console.log("onBlur", event)
                                        if (event.target.value !== "") {
                                            setNoTickerSelected(false);
                                            advanceWizardState(0, wizardState, setWizardState);
                                        }
                                    }}}
                                    autocompleteProps={{ fullWidth: true, size: "small", 
                                        autoHighlight: true, autoComplete: true,
                                        autoSelect: true,
                                        blurOnSelect: true,  
                                        disableCloseOnSelect: true,
                                        onChange: (event) => {  
                                            //console.log("onChange", event);
                                            if (event['_reactName'] === "onClick") {    // needed to ignore the subsequent blur event 
                                                if (event.target.textContent !== "") {  // onClick doesn't have the string value in event.target.value 
                                                    setNoTickerSelected(false);
                                                    advanceWizardState(0, wizardState, setWizardState);
                                                } else {
                                                    setNoTickerSelected(true);
                                                }
                                            }
                                        },
                                        isOptionEqualToValue: (optValue, value) => optValue.ticker === value.ticker && optValue.name === value.name && optValue.label === value.label
                                        // renderOption: renderAutocompleteOption,
                                    }}
                            /></div>
                        </OnboardingWizard>
                    </Grid>                                    
                    {/* <Grid xs={1}>
                        <OnboardingWizard step={1} placement="left">
                        <SelectElement 
                            name={searchBarComponentName[1]}
                            label="Form Type" 
                            disabled={noTickerSelected}
                            // required
                            options={
                                SUPPORTED_FORM_TYPES.map(
                                    (value) => (
                                        {'id': value, 'label': value}
                                    )
                                )
                            }                            
                            onChange={() => {
                                advanceWizardState(1, wizardState, setWizardState);
                            }}                            
                            fullWidth size="small"/>
                        </OnboardingWizard>
                    </Grid> */}
                    <Grid xs={0.5}>     
                    <OnboardingWizard step={1} placement="bottom">
                        <Button 
                        // sx = {{backgroundColor: 'black'}}
                            name={searchBarComponentName[2]}
                            disabled={noTickerSelected}
                            variant='contained' type='submit' 
                            onClick={
                                () => {                                  
                                    advanceWizardState([0, 1], wizardState, setWizardState);
                                }
                            }>
                                <SearchIcon />Search</Button>                        
                    </OnboardingWizard>
                    </Grid>
                    <Grid xs={3.5}>
                        <Box sx={{display: "flex", justifyContent: 'right', pr: "15px"}}>
                            <ThemeProvider theme={excelAddInButtonTheme}>
                                <Button component="a" href={process.env.REACT_APP_EXCELADDIN_URL} target="_blank" variant="contained" sx={{borderRadius: 50, pl: 3, pr: 3, fontSize: "13px"}}>Get Excel Add-in</Button>    
                            </ThemeProvider>                            
                            <Button sx={{borderRadius: 50, pl: 0, pr: 0, color: "var(--brand-text-secondary)"}} component={Link} to="/support"><HelpOutlineIcon fontSize='medium'/></Button>
                        </Box>
                    </Grid>                    
                </Grid>
            </FormContainer>
        </Container>
        </Box>
    )
}

export default SearchBar;