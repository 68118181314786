import { Fragment, useState, useEffect, useContext } from 'react';
import { Modal, Typography, Paper, Box, useMediaQuery, IconButton} from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { OnboardingWizardContext } from './Contexts';
import CloseIcon from '@mui/icons-material/Close';
import { useCookies } from 'react-cookie';
import ConfettiExplosion from 'react-confetti-explosion';
import { useTheme } from '@emotion/react';



const HtmlTooltip = styled(
    ({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }}/>
    ))
    (
        ({ theme }) => (
            {
                [`& .${tooltipClasses.arrow}`]: {                    
                    "&::before": {
                        backgroundColor: "var(--mui-palette-warning-light)",
                        border: "3px solid var(--mui-palette-warning-dark)"
                      }
                },
                [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: "var(--mui-palette-warning-light)",
                    color: 'var(--mui-palette-primary-contrastText)',
                    minWidth: "200px",
                    maxWidth: "350px",                    
                    paddingTop: "20px",
                    paddingBottom: "20px", 
                    paddingRight: "20px", 
                    paddingLeft: "20px",
                    fontSize: "1.25rem",
                    border: '3px solid var(--mui-palette-warning-dark)',
                },
            }
        )
    );

export const ONBOARDING_STEPS = {
    0: (
        <Fragment>
            <Typography color="inherit">Let's walk through how to use deepKPI.</Typography>
            <Typography color="inherit">First, enter a ticker.  Let's use <b>SNAP</b> as an example.</Typography>
        </Fragment>), 
    1: (
        <Fragment>
            <Typography color="inherit">Click the <b>Search</b> button.</Typography>            
        </Fragment>
    ),
    2: (
        <Fragment>
            <Typography color="inherit">
                We've pre-selected the latest data point to show you 
                where it was found in the SEC filing on the right.
            </Typography>
            <br/>
            <Typography>
                <b>Try auditing another data point by clicking on it.</b>
            </Typography>
        </Fragment>
    ),
    // 4: (
    //     <Fragment>
    //         <Typography color="inherit">Click the checkbox to select this time series for export.</Typography>            
    //     </Fragment>
    // ),
    // 5: (
    //     <Fragment>
    //         <Typography color="inherit">Finally, click the button to export your selected time series to Excel.</Typography>            
    //     </Fragment>
    // ),
    3: (
        <Fragment>
            <Typography color="inherit">To view KPIs extracted from tables in SEC filings, click the <b>TABLES</b> tab.</Typography>            
        </Fragment>
    ),
    4: (
        <Fragment>
            <Typography>
                To audit a data point, click on any value in the table.
            </Typography>
        </Fragment>
    ),
    5: (
        <Fragment>
            <Typography color="inherit">Click the checkbox to select this table for export.</Typography>            
        </Fragment>
    ),
    6: (
        <Fragment>
            <Typography color="inherit">Click here to export the selected table.</Typography>            
        </Fragment>
    ),
    7: null  /* END OF WIZARD */
};


export function advanceWizardState(currentStep, wizardState, setWizardState) {     
    let currentStepArray = [];
    if (Array.isArray(currentStep)) {
        currentStepArray = [...currentStep];
    } else {
        currentStepArray = [currentStep];
    }

    // console.log(wizardState);
    const tmp = [...wizardState];
    currentStepArray.forEach(
        (currentStep) => {
            const currentStepIdx = tmp.indexOf(currentStep);
            if (currentStepIdx > -1) {
                tmp.splice(currentStepIdx, 1);
            }
        }
    );
    setWizardState(tmp);    
    // console.log("advanceWizardState", tmp); 
};

export const OnboardingWizard = (props) => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const [ wizardState, setWizardState ] = useContext(OnboardingWizardContext);
    const [ cookies, setCookies ] = useCookies(['DEEPKPI_betaLoginModal']);
    const [ isOpen, setIsOpen ] = useState(false);

    
    useEffect(() => {                        
        setIsOpen((cookies['DEEPKPI_betaLoginModal'] === 1) && wizardState[0] === props.step && !isSm);
    }, [cookies, wizardState, props.step, isSm]);

    const handleClose = (event) => {
        setIsOpen(false);
        localStorage.setItem('DEEPKPI_onboardingWizardState', "[]");
    }

    return <HtmlTooltip 
        open={isOpen} 
        width={props.width} 
        placement={props.placement} 
        title={
            <>
            <div style={{ position: 'absolute', top: 6, right: 6, padding: 0 }}>
                <IconButton color='inherit'><CloseIcon fontSize="small" onClick={handleClose}/></IconButton>
            </div>
            <div style={{paddingTop: 10}}>
                {ONBOARDING_STEPS[props.step]}
            </div>
            </>
        }>
            {props.children}
        </HtmlTooltip>;
}



const largeProps = {
    force: 0.8,
    duration: 3000,
    particleCount: 300,    
    width: 3000,
    zIndex: 100,
    colors: ['#041E43', '#1471BF', '#5BB4DC', '#FC027B', '#66D805'],
};

export const OnboardingFinished = (props) => {
    const [wizardState, setWizardState] = useContext(OnboardingWizardContext);
    const [openState, setOpenState] = useState(false);
    
    // console.log(cookie);

    useEffect(
        () => {
            setOpenState(wizardState[0] === 7);                     
            localStorage.setItem("DEEPKPI_onboardingWizardState", JSON.stringify(wizardState));
//            console.log("wizardState change", localStorage.getItem("DEEPKPI_onboardingWizardState"));
        }, 
        [wizardState]
    )
    
    const handleClose = (event) => {
        setOpenState(false);
        advanceWizardState(7, wizardState, setWizardState);
    }

    return  <>
            {openState && <Box sx={{position: 'absolute', top: '50%', left: '50%'}}><ConfettiExplosion {...largeProps}/></Box>}
            
            <Modal open={openState}             
                onClose={() => {advanceWizardState(7, wizardState, setWizardState)}}
                sx={{display: {xs: 'none', md: 'block', lg: 'block'}}}>                    
                <Paper 
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: "var(--mui-palette-warning-light)",
                        color: 'var(--mui-palette-primary-contrastText)',
                        width: "500px",       
                        p: 4,                       
                        border: '3px solid var(--mui-palette-warning-dark)',
                    }}
                >                                     
                    {<div style={{ position: 'absolute', top: 6, right: 6, padding: 0 }}>
                        <IconButton color='inherit'><CloseIcon fontSize="small" onClick={handleClose}/></IconButton>
                    </div>}
                    {props.children}
                </Paper>
            </Modal>
            </>
}
