import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { makeGetRequest, makePostRequest, makeDeleteRequest} from 'revelata-common-ui';


const WatchlistButton = ({ ticker, shouldRender }) => {
    const [isInWatchlist, setIsInWatchlist] = useState(false);
    const [buttonText, setButtonText] = useState("");

    const [isHovering, setIsHovering] = useState(false);

  


    const [accessToken, setAccessToken] = useState(null);
    const { route, user } = useAuthenticator((context) => [context.route, context.user]);
    useEffect(
        () => {
            Auth.currentSession()
                .then(resp => setAccessToken(resp.getIdToken().getJwtToken()))
                .catch(error => { console.log(error); })
        },
        []
    );

    useEffect(
        () => {
            console.log("Requesting User Watchlist", user);
            if (user && user.username) {
                makeGetRequest(process.env.REACT_APP_API_BASE_URL + `/watchlist/${user.username}`, accessToken)
                    .then(data => {
                        const tickers = data.watchlist;
                        console.log("Watchlist", tickers);
                        console.log("Ticker", ticker);
                        console.log("IsInWatchlist", tickers.includes(ticker));
                        setIsInWatchlist(tickers.includes(ticker));
                        setButtonText(tickers.includes(ticker) ? "Watching" : "Watch");
                    })
                    .catch(error => console.log(error));
            }
        },
        [user, accessToken]
    );


    useEffect(() => {
        if (isInWatchlist) {
            setButtonText("Watching");
        } else if (!isInWatchlist && buttonText === "Watching") {
            setButtonText("Watch");
        }
    }, [isInWatchlist]);

    const handleButtonClick = () => {
        const newStatus = !isInWatchlist;
        setIsInWatchlist(newStatus);
        const request = newStatus ? makePostRequest : makeDeleteRequest;
        request(`${process.env.REACT_APP_API_BASE_URL}/watchlist/${user.username}/${ticker}`, accessToken);
    };

    const handleMouseEnter = () => {
        setIsHovering(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovering(false);
      };

    const buttonStyles = {
        ml: 2,
        color: "var(--mui-palette-grey-600)",
        fontSize: "0.75rem",
        textTransform: "none",
        width: "100px", // Set a fixed width
        justifyContent: "flex-start" // Align text and icon to the left
    };
    
    if (buttonText === "") {
        return null;
    }

    if (!shouldRender) {
        return null;
    }

    return (
        <Button
            startIcon={<VisibilityIcon />}
            sx={buttonStyles}
            onClick={handleButtonClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {isHovering && buttonText == "Watching"? "Unwatch" : buttonText}
        </Button>
    );
};

export default WatchlistButton;